import React from 'react'

import logo from '../../Assets/logoTransparent.svg';
import logoText from '../../Assets/LogoText.png'

export default function LoveguardLogoComponent() {
    return (
        <a href="/" className="brand-name">
            <div style={{ display: 'flex' }}>
                <img src={logo} width={24} style={{ marginTop: '6px' }} />
                <img src={logoText} style={{ height: '24px', marginTop: '10px', marginLeft: '12px' }} />
            </div>
        </a>
    )
}
