import React from 'react'

export default function DataDeletion() {
    return (
        <div>
            <p>
                At any time, you have the right to request the deletion of your personal data that we have collected and stored. To initiate the process of data deletion, please contact us using the information provided below:
            </p>
            <strong>forman@loveguard.cloud</strong>

            <p>Upon receiving your request, we will take reasonable steps to verify your identity and ensure that the data being deleted corresponds to your account. Please note that certain data may be exempt from deletion if it is necessary for legal or legitimate business purposes, such as fulfilling contractual obligations or complying with legal obligations.</p>

            <p>Once your data has been successfully deleted from our systems, it will no longer be accessible or retrievable. However, please be aware that residual copies of your data may remain in our backup systems or archives for a limited period.</p>

            <p>We strive to promptly respond to data deletion requests, but it may take some time to complete the process depending on the complexity of the request and the volume of data involved.</p>

            <p>If you have any concerns or questions regarding the deletion of your personal data, please reach out to us using the contact information provided.</p>

        </div>
    )
}
