import React from 'react'
import ReactMarkdown from 'react-markdown';

export default function PrivacyPolicyApp() {
    return (
        <div style={{padding: '40px'}}>
            <ReactMarkdown>
                {`**Zásady ochrany osobních údajů** 

  

Tyto zásady ochrany osobních údajů se vztahují na aplikaci LOVEGUARD (dále jen „Aplikace“) pro mobilní zařízení, kterou vytvořila společnost LOVEGUARD s.r.o. (dále jen „poskytovatel služeb“) jako služba Freemium. Tato služba je určena k použití „TAK, JAK JE“. 

  

**Shromažďování a používání informací** 

  

Aplikace shromažďuje informace, když ji stahujete a používáte. Tyto informace mohou zahrnovat informace jako např 

  

* Adresa internetového protokolu vašeho zařízení (např. IP adresa) 

* Stránky aplikace, které navštívíte, čas a datum vaší návštěvy, čas strávený na těchto stránkách 

* Čas strávený na aplikaci 

* Operační systém, který používáte na svém mobilním zařízení 

  

Aplikace neshromažďuje přesné informace o poloze vašeho mobilního zařízení. 

  

Aplikace shromažďuje polohu vašeho zařízení, což pomáhá poskytovateli služeb určit vaši přibližnou geografickou polohu a využít ji níže uvedenými způsoby: 

  

* Geolokační služby: Poskytovatel služeb využívá údaje o poloze k poskytování funkcí, jako je personalizovaný obsah, relevantní doporučení a služby založené na poloze. 

* Analýzy a vylepšení: Agregované a anonymizované údaje o poloze pomáhají poskytovateli služeb analyzovat chování uživatelů, identifikovat trendy a zlepšit celkový výkon a funkčnost aplikace. 

* Služby třetích stran: Poskytovatel služeb může pravidelně předávat externím službám anonymizované údaje o poloze. Tyto služby jim pomáhají při vylepšování aplikace a optimalizaci jejich nabídky. 

  

Poskytovatel služeb může použít vámi poskytnuté informace, aby vás čas od času kontaktoval, aby vám poskytl důležité informace, požadovaná upozornění a marketingové propagace. 

  

Pro lepší zážitek při používání aplikace může poskytovatel služeb vyžadovat, abyste nám poskytli určité osobní údaje, včetně, ale bez omezení na e-mail, celé jméno, pohlaví, věk, datum narození, lékařské záznamy, adresu. Informace, které si poskytovatel služeb vyžádá, budou uchovány a použity tak, jak je popsáno v těchto zásadách ochrany osobních údajů. 

  

**Přístup třetí strany** 

  

Externím službám jsou pravidelně předávány pouze agregované, anonymizované údaje, které pomáhají poskytovateli služeb zlepšovat aplikaci a jejich služby. Poskytovatel služeb může sdílet vaše informace s třetími stranami způsoby, které jsou popsány v tomto prohlášení o ochraně osobních údajů. 

  

Vezměte prosím na vědomí, že Aplikace využívá služby třetích stran, které mají vlastní Zásady ochrany osobních údajů o nakládání s údaji. Níže jsou uvedeny odkazy na zásady ochrany osobních údajů poskytovatelů služeb třetích stran používaných aplikací: 

  

* [Služby Google Play](https://www.google.com/policies/privacy/) 

* [AdMob](https://support.google.com/admob/answer/6128543?hl=cs) 

* [Google Analytics pro Firebase](https://firebase.google.com/support/privacy) 

* [Facebook](https://www.facebook.com/about/privacy/update/printable) 

* [Mixpanel](https://mixpanel.com/legal/privacy-policy/) 

  

Poskytovatel služeb může zpřístupnit Uživatelem poskytnuté a automaticky shromážděné informace: 

  

* jak to vyžaduje zákon, jako je například vyhovění předvolání k soudu nebo podobnému právnímu procesu; 

* když se v dobré víře domnívají, že zveřejnění je nezbytné pro ochranu jejich práv, ochranu vaší bezpečnosti nebo bezpečnosti ostatních, vyšetřování podvodu nebo reakci na žádost vlády; 

* se svými poskytovateli důvěryhodných služeb, kteří pracují jejich jménem, nepoužívají nezávisle informace, které jim poskytujeme, a zavázali se dodržovat pravidla stanovená v tomto prohlášení o ochraně osobních údajů. 

  

**Práva na odhlášení** 

  

Veškeré shromažďování informací aplikací můžete snadno zastavit jejím odinstalováním. Můžete použít standardní procesy odinstalace, které mohou být dostupné jako součást vašeho mobilního zařízení nebo prostřednictvím tržiště mobilních aplikací nebo sítě. 

  

**Zásady uchovávání údajů** 

  

Poskytovatel služeb bude uchovávat údaje poskytnuté uživatelem tak dlouho, dokud budete Aplikaci používat, a přiměřenou dobu poté. Pokud si přejete, aby smazal data poskytnutá uživatelem, která jste poskytli prostřednictvím aplikace, kontaktujte je prosím na adrese forman@loveguard.cloud a oni vám v přiměřené době odpoví. 

  

**Děti** 

  

Poskytovatel služeb nepoužívá Aplikaci k vědomému získávání dat od dětí mladších 13 let nebo jejich marketingu. 

  

Aplikace neoslovuje nikoho mladšího 13 let. Poskytovatel služeb vědomě neshromažďuje osobní údaje od dětí mladších 13 let. V případě, že Poskytovatel služeb zjistí, že dítě mladší 13 let poskytlo osobní údaje, Poskytovatel služeb tyto okamžitě vymaže ze svých serverů. Pokud jste rodič nebo opatrovník a víte, že nám vaše dítě poskytlo osobní údaje, kontaktujte prosím poskytovatele služeb (forman@loveguard.cloud), aby mohl podniknout potřebné kroky. 

 

**Zabezpečení** 

  

Poskytovatel služeb se stará o ochranu důvěrnosti vašich informací. Poskytovatel služeb poskytuje fyzické, elektronické a procedurální záruky na ochranu informací, které poskytovatel služeb zpracovává a uchovává. 

  

**Změny** 

  

Tyto zásady ochrany osobních údajů mohou být z jakéhokoli důvodu čas od času aktualizovány. Poskytovatel služeb vás bude informovat o jakýchkoli změnách Zásad ochrany osobních údajů aktualizací této stránky novými Zásadami ochrany osobních údajů. Doporučujeme vám, abyste si tyto Zásady ochrany osobních údajů pravidelně prohlíželi ohledně jakýchkoli změn, protože další používání se považuje za schválení všech změn. 

  

Tyto zásady ochrany osobních údajů jsou účinné od 28.08.2024 

  

**Váš souhlas** 

  

Používáním aplikace souhlasíte se zpracováním vašich údajů, jak je uvedeno v těchto Zásadách ochrany osobních údajů nyní a v našem znění. 

  

**Kontaktujte nás** 

  

Máte-li jakékoli dotazy týkající se ochrany osobních údajů při používání aplikace nebo máte dotazy týkající se postupů, kontaktujte prosím poskytovatele služeb prostřednictvím e-mailu na adrese forman@loveguard.cloud. 

  

* * * `}
            </ReactMarkdown>
        </div>
    )
}
