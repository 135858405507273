import { Button, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie';
import { useMediaPredicate } from 'react-media-hook';

export default function CookiesScene() {
    const [cookies, setCookie, removeCookie, updateCookies] = useCookies(['analytics']);
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const [isVisible, setIsVisible] = useState(true);
    useEffect(()=>{
        if(cookies.analytics === true)
        setIsVisible(false);
    },[]);
    // const theme = useTheme();
    const handleConsent = () => {
        setCookie('analytics', 'true', { path: '/' });
        setIsVisible(false);
    };
    const decilineAll = () => {
        removeCookie('analytics');
        updateCookies();
        setIsVisible(false);
    }
    return (
        <div style={{display: isVisible ? 'block':'none'}}>
            <div style={{ position: 'fixed', left: '0px', bottom: '0px', width: '100%', height: biggerThan700 ? '172px' : '16em', background: '#F5F5F5', zIndex: 1001, textAlign: 'center', borderTop: '1px solid red', padding: biggerThan700 ? '0px 340px' : '0px' }}>
                <div style={{ position: 'fixed', right: '0px', paddingBottom: '40px' }}>
                    <Button onClick={decilineAll} sx={{ color: 'grey', textDecoration: 'underline' }}>pokračovat bez cookies</Button>
                </div>

                <div style={{ margin: '32px 20px 20px 12px' }}>
                    <Typography variant='body2'>Používáme soubory cookie a podobné technologie, abychom porozuměli tomu, jak používáte naše služby, zlepšili vaši zkušenost a poskytli vám personalizovaný obsah a reklamu. Kliknutím na „Přijmout“ přijímáte všechny soubory cookie. Chcete-li spravovat své soubory cookie a dozvědět se více o našem používání souborů cookie, klikněte na „Nastavit“.</Typography>
                </div>

                <div >
                    <Button variant='contained' sx={{ marginRight: '20px' }} onClick={handleConsent}>Přijmout</Button>
                    <Button onClick={decilineAll} variant='outlined' sx={{ marginLeft: '20px' }}>Nastavit</Button>
                </div>
            </div>
        </div>
    );
};