import React from 'react'
import Navbar from '../Navigation/Navbar';
import '../../Components/Styles/MainScene.css';
import { Button, Grid, Typography, useTheme, Paper } from '@mui/material';
import { useMediaPredicate } from 'react-media-hook';
import naramek from '../../Assets/NaramekLoveguard.png';
import motor from '../../Assets/motor.png';
import imgKlic from '../../Assets/imgKlic.png';
import imgNaramek from '../../Assets/imgNaramek.png';
import imgOdkazZdarma from '../../Assets/imgOdkazZdarma.png';
import pripomene from '../../Assets/pripomene.png';
import imgPenize from '../../Assets/imgPenize.png';
import imgPredregistrace from '../../Assets/imgPredregistrace.png';
import imgPremium from '../../Assets/imgPremium.png';
import imgSdilejNas from '../../Assets/imgSdilejNas.png';
import cas1x1 from '../../Assets/cas1x1.png';
import { NotificationsActiveOutlined } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { t } from 'i18next';
import PropTypes from "prop-types";

function MainScene() {
    const { t } = useTranslation();
    const theme = useTheme();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

}

export default function Klicenka() {
    return (
        <div className='app'>
            <Navbar />
            <div className='flexRowClose' style={{ marginTop: '48px' }}>
                <div style={{ marginTop: '20px'}}>
                <iframe width="336" height="192" src="https://www.youtube.com/embed/TE2NAs_ErP0?si=1ATCOAIQqIqlq6Ar&autoplay=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </div>
                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${imgKlic})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#FFFFFFde', fontWeight: 'bold', fontSize: '28px' }}>Proč jsem dostal <b style={{ color: '#F34848' }}>KLÍČ</b>enku?</Typography>
                            <Typography variant='body1' sx={{ padding: '20px 20px 20px 20px', height: 'auto' }}><b style={{ fontWeight: 'bold' }}>
                                <ul style={{ padding: '20px' }}>
                                    <li style={{ paddingBottom: '20px' }}>Tvé <b style={{ color: '#F34848' }}>dobře zamýšlené jednání</b> se shodovalo s tím, co chci šířit i já.</li>

                                    <li style={{ paddingBottom: '20px' }}>Základním kamenem zdravé společnosti jsou jedinci jako jsi ty. <b style={{ color: '#F34848' }}>Lidé pomáhající ostatním</b>. Muži, kteří mají kus gentlemanství v sobě.</li>

                                    <li style={{ paddingBottom: '20px' }}><b style={{ color: '#F34848' }}>Lidé zlepšující náladu</b> ve svém okolí, kteří dokáží vykouzlit úsměvy na tvářích.</li>

                                    <li>Svět může být lepším místem, když <b style={{ color: '#F34848' }}>takových lidí bude víc</b>.</li>
                                </ul></b>
                            </Typography>

                        </div>
                    </div>
                </div>
                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${motor})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold', fontSize: '28px' }}>Vize</Typography>
                            <Typography variant='body1' sx={{ padding: '20px 20px 20px 20px', height: 'auto' }}>je zlepšovat vztahy mezi lidmi ve dvou rovinách:<br /><br />
                                <ol style={{ paddingLeft: '28px' }}>
                                    <li style={{ fontWeight: 'bold', fontSize: '20px' }}>Podpora vztahu:</li>
                                    <ul>
                                        <li>vytváříme <b style={{ color: '#F34848' }}>aplikaci pro muže s partnerkou</b>, kteří chtějí žít v harmonickém vztahu bez zbytečných nedorozumění. Víc najdeš <a href='/' style={{ color: '#F34848', textDecoration: 'underline' }}>tady</a>.</li>
                                    </ul> <br />
                                    <li style={{ fontWeight: 'bold', fontSize: '20px' }}    >Podpora okolí</li>
                                    <ul>
                                        <li>Šířit gentlemanství a vědomé myšlení. Chci mužům ukázat <b style={{ color: '#F34848' }}>gentlemanské zásady</b> a pomoci okolí tím, že se lidé kolem nás budou cítit lépe. </li>
                                    </ul>
                                </ol>
                            </Typography>
                        </div>
                    </div>
                </div>
                <div>
                    <Typography variant='h4' sx={{ color: '#F34848', fontWeight: 'bold', marginLeft: '-20px', marginTop: '20px' }}>Líbí se ti naše vize?</Typography>
                </div>
                <div>
                    <Typography variant='h4' sx={{ color: '#272727', fontWeight: 'bold' }}>Pošli ji dál!</Typography>
                </div>
                <Typography variant='body1' sx={{ margin: '0px -20px 20px -20px' }}><br />Pomůžeš tím šířit naše hodnoty.<br /><br />
                    Záleží jen na tobě, jestli se ti chce.<br />Stačí jediný skutek, kterým to posíláš dál.
                </Typography>

                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${imgSdilejNas})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold', fontSize: '28px' }}>LOVEGUARD <b style={{ color: '#FFFFFF' }}>Society</b></Typography>
                            <Typography variant='body1' sx={{ padding: '20px 20px 20px 20px', height: 'auto' }}> Připoj se do naší <b style={{ color: '#F34848' }}>soukromé skupiny</b>. Je určena pro všechny muže, kteří chtějí být součástí podpůrného a inspirujícího společenství. Zde se setkáváme, abychom sdíleli <b style={{ color: '#F34848' }}>rady, zkušenosti, názory a moudra</b>, které nám pomáhají růst jako jednotlivcům a podporují nás v našich cílech.
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px', marginBottom: '-20px' }}>
                            <Button variant='contained' sx={{ minWidth: '90%' }} href='https://www.facebook.com/groups/804023694343382' target='blank'>Stát se členem komunity</Button>
                        </div>
                    </div>
                </div>
                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${pripomene})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold', fontSize: '28px' }}>Facebook Like & Follow</Typography>
                            <Typography variant='body1' sx={{ padding: '20px 20px 20px 20px', height: 'auto' }}>Komunitu vytváříme na Facebooku, jelikož je to místo, kde je nejvíce lidí z naší cílové skupiny. Zatím sbíráme základnu gentlemanů pro stránku a ve správný okamžik oživíme i skupinu, kde budeme moci sdílet své postřehy vzájemně.
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px', marginBottom: '-20px' }}>
                            <Button variant='contained' sx={{ minWidth: '90%' }} href='https://www.facebook.com/profile.php?id=100089619233058' target='blank'>Like Facebook Stránku</Button>
                        </div>
                    </div>
                </div>

                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${imgPredregistrace})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold', fontSize: '28px' }}>Předregistrace do Appky</Typography>
                            <Typography variant='body1' sx={{ padding: '20px 20px 20px 20px', height: 'auto' }}>Aplikaci zatím ještě vytváříme, ale už nyní je možné se registrovat a vyzkoušet si například <a href='/quiz' style={{ color: '#F34848', textDecoration: 'underline' }}>kvíz lásky</a>, který je jedním z předpokladů, jak pochopit partnerskou dynamiku a vytvořit si harmonický vztah.
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px', marginBottom: '-20px' }}>
                            <Button variant='contained' sx={{ minWidth: '90%' }} href='/signup'>Předregistrovat se do alfa verze</Button>
                        </div>
                    </div>
                </div>
                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${cas1x1})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold', fontSize: '28px' }}>Sdílej Přes Facebook</Typography>
                            <Typography variant='body1' sx={{ padding: '20px 20px 20px 20px', height: 'auto' }}>Nasdílej na svém osobním profilu na Facebooku svůj příběh, za jaký skutek jsi podle sebe dostal klíčenku a označ stránku v něm <b style={{ color: '#F34848' }}>@Loveguard</b> a já to moc rád doplním o svůj pohled.
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px', marginBottom: '-20px' }}>
                            <Button variant='contained' sx={{ minWidth: '90%' }} href='https://www.facebook.com/' target='blank'>Sdílej náš příběh</Button>
                        </div>
                    </div>
                </div>
                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${imgOdkazZdarma})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold', fontSize: '28px' }}>Sdílej Odkaz s Kumpány</Typography>
                            <Typography variant='body1' sx={{ padding: '20px 20px 20px 20px', height: 'auto' }}>Zkopíruj odkaz níže a pošli ho kamarádům, kteří mají partnerku, nebo kus gentlemana v sobě. Nic není upřímnější, než “pošli to dál” způsob, který se šíří mezi přáteli s dobrým úmyslem.
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px', marginBottom: '-20px' }}>
                            <Button variant='contained' sx={{ minWidth: '90%' }} onClick={() => {
                                navigator.clipboard.writeText("https://loveguard.cloud/").then(() => {
                                    alert('Odkaz zkopírován do schránky.');
                                })
                            }}>Zkopírovat odkaz DO SCHRÁNKY</Button>
                        </div>
                    </div>
                </div>
                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${imgPremium})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold', fontSize: '28px' }}>Sdílej za Premium</Typography>
                            <Typography variant='body1' sx={{ padding: '20px 20px 20px 20px', height: 'auto' }}>Registruj se a pošli odkaz kamarádům, kteří mají partnerku, nebo kus gentlemana v sobě. Jakmile bude aplikace na světe, tak za každou registraci kamaráda ze svého odkazu získáš 1 měsíc premium zdarma.
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px', marginBottom: '-20px' }}>
                            <Button variant='contained' sx={{ minWidth: '90%' }}>Registrovat a získat premium</Button>
                        </div>
                    </div>
                </div>
                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${imgPenize})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold', fontSize: '28px' }}>Přihoď Kačku</Typography>
                            <Typography variant='body1' sx={{ padding: '20px 20px 20px 20px', height: 'auto' }}>Každá koruna se počítá. Stačí zkopírovat číslo účtu z tlačítka a sám si zvol částku, kterou chceš přispět.
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px', marginBottom: '-20px' }}>
                            <Button variant='contained' sx={{ minWidth: '90%' }} onClick={() => {
                                navigator.clipboard.writeText("forman@loveguard.cloud").then(() => {
                                    alert('Huh, zatím nemáme transparentní učet :D, ale cením si Tvého zájmu. Pokud chceš za každou cenu přispět, tak mi napiš a domluvíme se přes email forman@loveguard.cloud, který máš teď uložený ve schránce ;)');
                                })
                            }}>Zkopírovat číslo účtu</Button>
                        </div>
                    </div>
                </div>
                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${imgNaramek})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold', fontSize: '28px' }}>Náramek Loveguard</Typography>
                            <Typography variant='body1' sx={{ padding: '20px 20px 20px 20px', height: 'auto' }}>Kup si za 299,- Kč náramek z chirurgické oceli s gravírovaným nápisem LOVEGUARD a propaguj nás. Navíc získej slevu 20 % na zbytek nákupu od <a href='https://www.instagram.com/naramky_corrall/' target='blank' style={{ color: '#F34848', textDecoration: 'underline' }}>@naramky_corrall</a>
                            </Typography>

                        </div>
                        <img src={naramek} style={{ width: '100%' }}></img>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px', marginBottom: '-20px' }}>
                            <Button variant='contained' sx={{ minWidth: '90%' }} href='https://forms.gle/8gTbjErQVNroRkKa9'>získat náramek a slevu 20 %</Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
