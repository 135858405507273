import { Button, MobileStepper, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import data from './questions.json';
import { Item } from './QuizOrderLovePriorities';
import QuizResultScene from './QuizResultScene';
import { useTranslation } from 'react-i18next';

interface QuizMatchSceneProps {
    dataLanguages: Item[] | null;
}

export default function QuizMatchScene(props: QuizMatchSceneProps) {
    const { dataLanguages } = props;
    const [actualRound, setActualRound] = useState<number>(0);
    const [actualLap, setActualLap] = useState<number>(1);
    const isLoaded = useRef(false);

    const [currentBracket, setCurrentBracket] = useState<any | null>();
    const [winner, setWinner] = useState<any | null>(null);
    const [resultData, setResultData] = useState<any | null>(null);
    const [winnersBracket, setWinnersBracket] = useState<any[] | null>();
    const [losersBracket, setLosersBracket] = useState<any[] | null>();
    const [third, setThird] = useState<any | null>(null);
    const [fourth, setFourth] = useState<any | null>(null);
    const [fifth, setFifth] = useState<any | null>(null);
    const { t } = useTranslation();
    useEffect(() => {
        console.log(data);
        // console.log(1 == "1");
    }, []);
    useEffect(() => {
        if (dataLanguages !== null)
            createBracket();
    }, [dataLanguages]);
    const createBracket = () => {
        setWinnersBracket([{
            playerOne: data.find(x => x.languageTypeId === dataLanguages![4].languageTypeId),
            playerTwo: data.find(x => x.languageTypeId === dataLanguages![3].languageTypeId),
            lap: 1
        },
        {
            playerOne: data.find(x => x.languageTypeId === dataLanguages![2].languageTypeId),
            playerTwo: data.find(x => x.languageTypeId === dataLanguages![1].languageTypeId),
            lap: 2
        },
        {
            playerOne: data.find(x => x.languageTypeId === dataLanguages![0].languageTypeId),
            playerTwo: undefined,
            lap: 3
        }, {
            playerOne: undefined,
            playerTwo: undefined,
            lap: 4
        }, {
            playerOne: undefined,
            playerTwo: undefined,
            lap: 5
        },
        {
            playerOne: undefined,
            playerTwo: undefined,
            lap: 99
        }]);
        setLosersBracket([{
            playerOne: undefined,
            playerTwo: undefined,
            lap: 1
        },
        {
            playerOne: undefined,
            playerTwo: undefined,
            lap: 2
        },
        {
            playerOne: undefined,
            playerTwo: undefined,
            lap: 3
        }]);

    }

    useEffect(() => {
        if (actualRound === 0) {
            if (winnersBracket !== null && winnersBracket !== undefined && isLoaded.current === false) {
                setCurrentBracket(winnersBracket[0]);
                isLoaded.current = true;
            }
        }
    }, [winnersBracket]);
    useEffect(() => {
        if (isLoaded.current === true) {
            if ((actualRound + 1) === 2) {
                setCurrentBracket(winnersBracket?.find(x => x.lap === 2));
                setActualLap(2);
            }
            if ((actualRound + 1) === 3) {
                setCurrentBracket(losersBracket?.find(x => x.lap === 1));
                setActualLap(1);
            }
            if ((actualRound + 1) === 4) {
                setCurrentBracket(winnersBracket?.find(x => x.lap === 3));
                setActualLap(3);
            }

            if ((actualRound + 1) === 5) {
                setCurrentBracket(winnersBracket?.find(x => x.lap === 4));
                setActualLap(4);
            }
            if ((actualRound + 1) === 6) {
                setCurrentBracket(losersBracket?.find(x => x.lap === 2));
                setActualLap(2);
            }
            if ((actualRound + 1) === 7) {
                setCurrentBracket(losersBracket?.find(x => x.lap === 3));
                setActualLap(3);
            }
            if ((actualRound + 1) === 8) {
                setCurrentBracket(winnersBracket?.find(x => x.lap === 5));
                setActualLap(5);
            }
            //když už se utkali dva hráči hrající finále a dopadlo to opačně než nyní, nastává ještě krok 99
            setActualRound(actualRound + 1);
        }
    }, [winnersBracket, losersBracket]);
    // useEffect(() => {
    //     getPlayersForStartRound();
    // }, []);
    useEffect(() => { console.log('winners', winnersBracket) }, [winnersBracket]);
    useEffect(() => { console.log('loosers', losersBracket) }, [losersBracket]);
    const win = () => {
        console.log(actualRound);
        if (actualRound === 1) {
            const updatedBracket = winnersBracket?.map((bracket, i) => {
                if (bracket.lap === 3 && bracket.playerTwo === undefined) {
                    bracket.playerTwo = currentBracket.playerOne;
                }
                return bracket;
            })
            setWinnersBracket(updatedBracket);
            const updatedLoosersBracket = losersBracket?.map((bracket, i) => {
                if (bracket.lap === 1 && bracket.playerTwo === undefined) {
                    bracket.playerTwo = currentBracket.playerTwo;
                }
                return bracket;
            })
            setLosersBracket(updatedLoosersBracket);

        }

        else if (actualRound === 2) {
            const updatedBracket = winnersBracket?.map((bracket, i) => {
                if (bracket.lap === 4 && bracket.playerTwo === undefined) {
                    bracket.playerTwo = currentBracket.playerOne;
                }
                return bracket;
            })
            setWinnersBracket(updatedBracket);
            const updatedLoosersBracket = losersBracket?.map((bracket, i) => {
                if (bracket.lap === 1 && bracket.playerOne === undefined) {
                    bracket.playerOne = currentBracket.playerTwo;
                }
                return bracket;
            })
            setLosersBracket(updatedLoosersBracket);

        }

        else if (actualRound === 3) {
            const updatedLoosersBracket = losersBracket?.map((bracket, i) => {
                if (bracket.lap === 2 && bracket.playerTwo === undefined) {
                    bracket.playerTwo = currentBracket.playerOne;
                }
                return bracket;
            })
            setLosersBracket(updatedLoosersBracket);
            setFifth(currentBracket.playerTwo);
        }
        else if (actualRound === 4) {
            const updatedBracket = winnersBracket?.map((bracket, i) => {
                if (bracket.lap === 4 && bracket.playerOne === undefined) {
                    bracket.playerOne = currentBracket.playerOne;
                }
                return bracket;
            })
            setWinnersBracket(updatedBracket);
            const updatedLoosersBracket = losersBracket?.map((bracket, i) => {
                if (bracket.lap === 2 && bracket.playerOne === undefined) {
                    bracket.playerOne = currentBracket.playerTwo;
                }
                return bracket;
            })
            setLosersBracket(updatedLoosersBracket);
        }
        else if (actualRound === 5) {
            const updatedBracket = winnersBracket?.map((bracket, i) => {
                if (bracket.lap === 5 && bracket.playerOne === undefined) {
                    bracket.playerOne = currentBracket.playerOne;
                }
                return bracket;
            })
            setWinnersBracket(updatedBracket);
            const updatedLoosersBracket = losersBracket?.map((bracket, i) => {
                if (bracket.lap === 3 && bracket.playerTwo === undefined) {
                    bracket.playerTwo = currentBracket.playerTwo;
                }
                return bracket;
            })
            setLosersBracket(updatedLoosersBracket);
        }

        else if (actualRound === 6) {
            // const updatedBracket = winnersBracket?.map((bracket, i) => {
            //     if (bracket.lap === 5 && bracket.playerOne === undefined) {
            //         bracket.playerOne = currentBracket.playerOne;
            //     }
            //     return bracket;
            // })
            // setWinnersBracket(updatedBracket);
            const updatedLoosersBracket = losersBracket?.map((bracket, i) => {
                if (bracket.lap === 3 && bracket.playerOne === undefined) {
                    bracket.playerOne = currentBracket.playerOne;
                }
                return bracket;
            })
            setLosersBracket(updatedLoosersBracket);
            setFourth(currentBracket.playerTwo);

        }
        else if (actualRound === 7) {
            const updatedBracket = winnersBracket?.map((bracket, i) => {
                if (bracket.lap === 5 && bracket.playerTwo === undefined) {
                    bracket.playerTwo = currentBracket.playerOne;
                }
                return bracket;
            })
            setWinnersBracket(updatedBracket);
            setThird(currentBracket.playerTwo);


        } else if (actualRound === 8) {
            setWinner(currentBracket.playerOne);
            console.log(currentBracket.playerOne.languageTypeId);
            countPoints(currentBracket.playerOne, currentBracket.playerTwo);
            //poslední fight
        }
        // setActualRound(actualRound + 1);
        // setWinners([...winners, dataLanguages!.find(x => x.languageTypeId === playerOne.languageTypeId)!]);
        // setLoosers([...loosers, dataLanguages!.find(x => x.languageTypeId === playerTwo.languageTypeId)!]);


    }
    const countPoints = (winner: any, looser: any) => {

        let result: any[] =
            [
                { languageTypeId: winner.languageTypeId, totalPoints: 25, text: winner.language },
                { languageTypeId: looser.languageTypeId, totalPoints: 20, text: looser.language },
                { languageTypeId: third.languageTypeId, totalPoints: 15, text: third.language },
                { languageTypeId: fourth.languageTypeId, totalPoints: 10, text: fourth.language },
                { languageTypeId: fifth.languageTypeId, totalPoints: 5, text: fifth.language }
            ];
        result.find(x => x.languageTypeId === dataLanguages![0].languageTypeId).totalPoints += 5;
        result.find(x => x.languageTypeId === dataLanguages![1].languageTypeId).totalPoints += 4;
        result.find(x => x.languageTypeId === dataLanguages![2].languageTypeId).totalPoints += 3;
        result.find(x => x.languageTypeId === dataLanguages![3].languageTypeId).totalPoints += 2;
        result.find(x => x.languageTypeId === dataLanguages![4].languageTypeId).totalPoints += 1;

        let q = result.sort((a, b) => { return a.totalPoints < b.totalPoints ? 1 : -1 });
        console.log(q);
        setResultData(q);
    }
    const lose = () => {
        console.log(actualRound);
        if (actualRound === 1) {
            const updatedBracket = winnersBracket?.map((bracket, i) => {
                if (bracket.lap === 3 && bracket.playerTwo === undefined) {
                    bracket.playerTwo = currentBracket.playerTwo;
                }
                return bracket;
            })
            setWinnersBracket(updatedBracket);
            const updatedLoosersBracket = losersBracket?.map((bracket, i) => {
                if (bracket.lap === 1 && bracket.playerTwo === undefined) {
                    bracket.playerTwo = currentBracket.playerOne;
                }
                return bracket;
            })
            setLosersBracket(updatedLoosersBracket);

        }
        // if (actualRound === 1) {
        //     const updatedBracket = winnersBracket?.map((bracket, i) => {
        //         if (bracket.lap === 3 && bracket.playerTwo === undefined) {
        //             bracket.playerTwo = currentBracket.playerTwo;
        //         }
        //         return bracket;
        //     })
        //     setWinnersBracket(updatedBracket);
        //     const updatedLoosersBracket = losersBracket?.map((bracket, i) => {
        //         if (bracket.lap === 1 && bracket.playerTwo === undefined) {
        //             bracket.playerOne = currentBracket.playerOne;
        //         }
        //         return bracket;
        //     })
        //     setLosersBracket(updatedLoosersBracket);
        // }
        else if (actualRound === 2) {
            const updatedBracket = winnersBracket?.map((bracket, i) => {
                if (bracket.lap === 4 && bracket.playerTwo === undefined) {
                    bracket.playerTwo = currentBracket.playerTwo;
                }
                return bracket;
            })
            setWinnersBracket(updatedBracket);
            const updatedLoosersBracket = losersBracket?.map((bracket, i) => {
                if (bracket.lap === 1 && bracket.playerOne === undefined) {
                    bracket.playerOne = currentBracket.playerOne;
                }
                return bracket;
            })
            setLosersBracket(updatedLoosersBracket);

        }
        // else if (actualRound === 2) {
        //     const updatedBracket = winnersBracket?.map((bracket, i) => {
        //         if (bracket.lap === 3 && bracket.playerTwo === undefined) {
        //             bracket.playerTwo = currentBracket.playerTwo;
        //         }
        //         return bracket;
        //     })
        //     setWinnersBracket(updatedBracket);
        //     const updatedLoosersBracket = losersBracket?.map((bracket, i) => {
        //         if (bracket.lap === 1 && bracket.playerTwo === undefined) {
        //             bracket.playerTwo = currentBracket.playerOne;
        //         }
        //         return bracket;
        //     })
        //     setLosersBracket(updatedLoosersBracket);
        // }
        else if (actualRound === 3) {
            const updatedLoosersBracket = losersBracket?.map((bracket, i) => {
                if (bracket.lap === 2 && bracket.playerTwo === undefined) {
                    bracket.playerTwo = currentBracket.playerTwo;
                }
                return bracket;
            })
            setLosersBracket(updatedLoosersBracket);
            setFifth(currentBracket.playerOne);
        }
        else if (actualRound === 4) {
            const updatedBracket = winnersBracket?.map((bracket, i) => {
                if (bracket.lap === 4 && bracket.playerOne === undefined) {
                    bracket.playerOne = currentBracket.playerTwo;
                }
                return bracket;
            })
            setWinnersBracket(updatedBracket);
            const updatedLoosersBracket = losersBracket?.map((bracket, i) => {
                if (bracket.lap === 2 && bracket.playerOne === undefined) {
                    bracket.playerOne = currentBracket.playerOne;
                }
                return bracket;
            })
            setLosersBracket(updatedLoosersBracket);
        }
        else if (actualRound === 5) {
            const updatedBracket = winnersBracket?.map((bracket, i) => {
                if (bracket.lap === 5 && bracket.playerOne === undefined) {
                    bracket.playerOne = currentBracket.playerTwo;
                }
                return bracket;
            })
            setWinnersBracket(updatedBracket);
            const updatedLoosersBracket = losersBracket?.map((bracket, i) => {
                if (bracket.lap === 3 && bracket.playerTwo === undefined) {
                    bracket.playerTwo = currentBracket.playerOne;
                }
                return bracket;
            })
            setLosersBracket(updatedLoosersBracket);
        }
        else if (actualRound === 6) {
            // const updatedBracket = winnersBracket?.map((bracket, i) => {
            //     if (bracket.lap === 5 && bracket.playerOne === undefined) {
            //         bracket.playerOne = currentBracket.playerTwo;
            //     }
            //     return bracket;
            // })
            // setWinnersBracket(updatedBracket);
            const updatedLoosersBracket = losersBracket?.map((bracket, i) => {
                if (bracket.lap === 3 && bracket.playerOne === undefined) {
                    bracket.playerOne = currentBracket.playerTwo;
                }
                return bracket;
            })
            setLosersBracket(updatedLoosersBracket);
            setFourth(currentBracket.playerOne);

        }
        else if (actualRound === 7) {
            const updatedBracket = winnersBracket?.map((bracket, i) => {
                if (bracket.lap === 5 && bracket.playerTwo === undefined) {
                    bracket.playerTwo = currentBracket.playerTwo;
                }
                return bracket;
            })
            setWinnersBracket(updatedBracket);
            setThird(currentBracket.playerOne);

        } else if (actualRound === 8) {
            setWinner(currentBracket.playerTwo);
            console.log(currentBracket.playerTwo);
            countPoints(currentBracket.playerTwo, currentBracket.playerOne);
            //poslední fight
        }
        // setActualRound(actualRound + 1);
        // setWinners([...winners, dataLanguages!.find(x => x.languageTypeId === playerTwo.languageTypeId)!]);
        // setLoosers([...loosers, dataLanguages!.find(x => x.languageTypeId === playerOne.languageTypeId)!]);
    }
    const getQuestionUpper = () => {

    }
    const getQuestionDowner = () => {
        if (data !== null && dataLanguages !== null) {
            if (actualRound === 0) {
                return data.find(x => x.languageTypeId === dataLanguages[dataLanguages.length - 2].languageTypeId)?.statements[0].statement;
            }
            else if (actualRound === 1) {
                return data.find(x => x.languageTypeId === dataLanguages[dataLanguages.length - 4].languageTypeId)?.statements[0].statement;
            }
            // else return data.find(x => x.languageTypeId === dataLanguages[dataLanguages.length - 2].languageTypeId)?.statements[actualRound].statement;
        }
    }
    if (resultData === null)
        return (
            <div >
                <div className='flexRowMatch'>
                    <Typography sx={{paddingTop: '1em'}} variant='h4'>{t('LoveTypesTournament')}</Typography>
                </div>
                <div className='flexRowMatch'>
                    {data !== null && <div>

                        <Typography variant='body1'><b>{t('LoveTypesTournamentTitleQuestion')}</b></Typography>
                        <div className='quizQuestion'>
                            {currentBracket && currentBracket !== null && <Button onClick={() => { win() }}>{t(currentBracket.playerOne.statements.find((x: any) => x.importance === actualLap)!.statement)}</Button>}
                        </div>
                        <div style={{ textAlign: 'center', padding: '8px' }}>
                            versus
                        </div>
                        <div className='quizQuestion'>
                            {currentBracket && currentBracket !== null && <Button onClick={() => { lose() }}>{t(currentBracket.playerTwo.statements.find((x: any) => x.importance === actualLap)!.statement)}</Button>}
                        </div>
                    </div>}

                </div>

                <div className='flexRow2'>
                    <MobileStepper style={{ width: '1000px' }}
                        variant="progress"
                        steps={9}
                        position="static"
                        activeStep={actualRound - 1}
                        sx={{ maxWidth: 600, flexGrow: 1 }}
                        nextButton={
                            <div />
                        }
                        backButton={
                            <div />
                        }
                    />
                </div>


            </div>
        )
    else return <QuizResultScene data={resultData} />
}
