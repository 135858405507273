import React from 'react'

export default function Privacy() {
    return (
        <div>Poslední aktualizace: 30.5.2023

            Děkujeme, že používáte naši aplikaci na rady a úkoly ve vztazích mezi mužem a ženou. Tento dokument popisuje, jakým způsobem shromažďujeme, zpracováváme a uchováváme vaše osobní údaje ve vztahu k naší aplikaci. Prosíme, pečlivě si přečtěte následující informace.

            Shromažďování osobních údajů
            Při používání naší aplikace na vás můžeme shromažďovat následující typy osobních údajů:

            1.1. Informace poskytnuté prostřednictvím Facebook přihlašování: Při používání Facebook přihlašování, shromažďujeme pouze vaše veřejné informace, které jsou povoleny Facebookem. Patří sem váš veřejný profilový obrázek, jméno a e-mailová adresa.

            1.2. Informace poskytnuté dobrovolně: Během používání aplikace můžete dobrovolně poskytnout další osobní údaje, jako jsou fotografie, jména lidí ve vašem okruhu, informace o vašich vztazích a podobně.

            Zpracování a využití osobních údajů
            Vaše osobní údaje jsou zpracovávány a využívány pro následující účely:

            2.1. Poskytování služeb: Vaše osobní údaje jsou používány k poskytování rady a úkolů vztahujících se k vztahům mezi mužem a ženou v rámci naší aplikace.

            2.2. Personalizace: S vaším souhlasem můžeme využívat vaše osobní údaje k personalizaci obsahu a nabídky v naší aplikaci.

            2.3. Komunikace: Můžeme vám zasílat informace o naší aplikaci, novinkách, aktualizacích a relevantních nabídkách na základě vašeho souhlasu.

            Sdílení osobních údajů
            Vaše osobní údaje nebudeme prodávat, pronajímat ani sdílet s třetími stranami, s výjimkou následujících situací:

            3.1. Spolupracující partneři: Můžeme sdílet vaše osobní údaje s našimi spolupracujícími partnery, kteří nám pomáhají poskytovat naše služby, avšak pouze v rozsahu nezbytném k plnění jejich úkolů.

            3.2. Právní požadavky: Pokud to vyžaduje zákon nebo nařízení, můžeme být povinni sdílet vaše osobní údaje s oprávněnými orgány.

            Bezpečnost
            Přijímáme přiměřená opatření k zajištění bezpečnosti vašich osobních údajů a ochraně proti neoprávněnému přístupu, ztrátě, zneužití nebo poškození.

            Uchovávání osobních údajů
            Vaše osobní údaje budeme uchovávat po dobu nezbytnou k dosažení účelů uvedených v této politice. Po skončení tohoto období budou vaše osobní údaje smazány nebo anonymizovány, pokud není stanoveno jinak.

            Vaše práva
            Máte právo požadovat přístup k vašim osobním údajům, opravit je, omezit jejich zpracování nebo je smazat, pokud je to v souladu s příslušnými zákony a předpisy.

            Kontakt
            Pokud máte jakékoli otázky, připomínky nebo požadavky týkající se naší Privacy Policy nebo způsobu, jakým nakládáme s vašimi osobními údaji, kontaktujte nás na níže uvedených kontaktních údajích:

            forman@loveguard.cloud

        </div>
    )
}
