import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useMediaPredicate } from 'react-media-hook';
import motor from '../../Assets/motor.png';
import { Button } from '@mui/material';
import results from './resultTexts.json';
import { ResetTvOutlined } from '@mui/icons-material';
import { useTranslation } from "react-i18next";

interface QuizResultSceneProps {
    data: any[];
}

export default function QuizResultScene(props: QuizResultSceneProps) {
    const biggerThan900 = useMediaPredicate("(min-width: 900px)");
    const biggerThan1200 = useMediaPredicate("(min-width: 1200px)");
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const [winnerName, setWinnerName] = useState('');
    const [winnerText, setWinnerText] = useState('');
    const { t } = useTranslation();
    useEffect(() => {
        // console.log(props.data[0]);
        let res = results.find(x => x.languageTypeId === props.data[0].languageTypeId);
        if (res) {
            setWinnerName(props.data[0].text);
            setWinnerText(res.statement);
        }

        // console.log(res.statement);
    }, [props.data]);
    const getColorForLanguage = (position: number) => {
        switch (position) {
            case 1:
                return '#F34848';
            case 2:
                return '#272727';
            case 3:
                return '#272727';
            case 4:
                return '#272727';
            case 5:
                return '#272727';
            default: return '#272727';
        }
    }

    return (
        <div>
            <div className='flexRowMatch'>
                <Typography sx={{paddingTop: '1em'}} variant='h4'>{t('YourLoveMix')} </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: biggerThan700 ? 'row' : 'column' }}>{props.data.map((result, index) => {
                if (biggerThan700)
                    return <div className='flexRow' style={{ boxShadow: '4px 4px 16px 4px rgba(0, 0, 0, 0.24', textAlign: 'center', color: '#FFFFFF', borderRadius: '8px', margin: '4em', height: (result.totalPoints * 10) + 'px', background: getColorForLanguage(index + 1) }}>{result.totalPoints} {t(result.text)}</div>
                else return <div className='' style={{ boxShadow: '4px 4px 16px 4px rgba(0, 0, 0, 0.24', borderRadius: '8px', margin: '1em', width: (result.totalPoints * 12.1) + 'px', height: '64px', background: getColorForLanguage(index + 1) }}>
                    <div style={{ marginTop: '20px' }}>
                        <Typography variant='body1' sx={{ textAlign: 'left', color: '#FFFFFF', paddingLeft: '8px' }}>{result.totalPoints} {t(result.text)}</Typography>
                    </div>

                </div>

            })}

            </div>
            <div className='flexRow2' style={{marginTop: '40px'}}>
                    <Button variant='contained' size='large' sx={{minWidth: '360px', margin: '20px'}}>{t('SaveResult')}</Button>
                    <Button variant='outlined'  size='large' sx={{minWidth: '360px', margin: '20px'}}>{t('ChallengeYourPartner')}</Button>
                </div>
            <div style={{padding: '1em'}}>
                

                <div className='flexRow'>
                    <Typography variant='h4'>
                    {t('PrimaryType')}
                    </Typography>

                </div>
                <div className='flexRow' style={{ backgroundImage: `url(${motor})`, height: '140px', maxWidth: '400px', backgroundSize: 'cover', borderRadius: '16px', marginTop: '20px', marginBottom: '20px', marginLeft: biggerThan700 ? '37%' : '0em'}}>
                    <Typography variant='h4' sx={{ fontStyle: 'bold', color: '#f34848', backgroundColor: '#272727CC', textAlign: 'center', borderRadius: '8px', margin: biggerThan700 ? '12px 20px 28px 20px' : '12px 20px 28px 20px', padding: '20px 20px 20px 20px' }}>{t(winnerName)}</Typography>
                </div>
                <div className='cardPic3    '>
                    <Typography variant='body1'>
                        {t(winnerText)}</Typography>
                </div>
            </div>
        </div>

    )
}
