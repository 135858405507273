import React from 'react'
import './Login.css';
import layoutPic from '../../Assets/LoveguardBanner7.png'; // with import
import layoutPicMobile from '../../Assets/layoutPictureMobile.png'; // with import
import { useMediaPredicate } from 'react-media-hook';
import LoveguardLogoComponent from '../Shared/LoveguardLogoComponent';
import { Button, Checkbox, TextField, Typography } from '@mui/material';
import FacebookLoginButton from '../FacebookLogin';
import Passwordbox from '../Shared/PasswordBox';
import { EbookRequest, LoveguardClient } from '../../Api/ApiServer';
import { LoveguardApiClient } from '../../Api/ApiBaseClient';
import { SuccessResponse } from '@greatsumini/react-facebook-login';
import { useTranslation } from "react-i18next";
import { CheckBox } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

interface LoginProps {
    singUp?: boolean;
}

export default function Login(props: LoginProps) {
    const { t } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const [email, setEmail] = React.useState('');
    const [agree, setAgree] = React.useState(true);
    const [interest, setInterest] = React.useState(true);
    const [password, setPassword] = React.useState('');
    const [textOdeslano, setTestOdeslano] = React.useState<string>('ZAREGISTROVAT');
    const navigate = useNavigate();
    const registerFCB = (data: SuccessResponse) => {
        // console.log
        new LoveguardApiClient().GetClientToAuth().registerAndLoginFCB(data.accessToken).then(x => { console.log(x) });
        // new Client
    }

    const registerLocal = () => {
        // console.log
        new LoveguardApiClient().GetClientToAuth().sendInterestMail(new EbookRequest({ email: email })).then(x => { if (x) navigate('/signuploicompleted'); else setTestOdeslano('Chybný Email') }).catch(x => { setTestOdeslano('Chyba') });
        // new Client
    }
    if (props.singUp === true)
        return (
            <div className="container">
                <div className={biggerThan700 ? 'background-image' : 'background-image-mobile'}>
                    <div className="login-form">
                        <div style={{ marginTop: '10px' }}>
                            <LoveguardLogoComponent />
                        </div>
                        <Typography sx={{ marginTop: '10px', color: 'white' }} variant='h5'>{t('Register')}</Typography>
                        {/*  <FacebookLoginButton isRegster onLogin={registerFCB} />
                        <div style={{ display: 'flex', width: '88%', margin: '20px', justifyContent: 'space-around' }}>
                            <div style={{ width: '40%', height: '1px', marginTop: '8px', background: 'red' }}></div>
                            <div style={{ width: '14%', marginLeft: '16px', fontSize: '14px' }}>{t('Or')}</div>
                            <div style={{ width: '40%', height: '1px', marginTop: '8px', background: 'red' }}></div>
                        </div> */}
                        <TextField value={email} onChange={(e) => { setEmail(e.currentTarget.value) }} label='Zadej svůj email' variant='outlined' sx={{
                            margin: '12px', width: '90%',
                            '& label': {
                                color: 'lightgrey', // změna barvy labelu
                            },
                            '& .MuiOutlinedInput-root': {
                                color: 'white', // změna barvy textu
                                '& fieldset': {
                                    borderColor: 'white', // změna barvy borderu
                                },
                                '&:hover fieldset': {
                                    borderColor: 'lightblue', // změna barvy borderu při hoveru
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#F34848', // změna barvy borderu při focusu
                                },
                            },
                        }} />
                        <Typography sx={{ color: 'white' }}>
                            <Checkbox checked={agree} onChange={(event, checked) => { setAgree(checked); }} ></Checkbox> Souhlasím se zpracováním údajů
                        </Typography>
                        {/* <Typography>
                            <Checkbox checked={interest} onChange={(event, checked) => { setInterest(checked); }}></Checkbox> Mám zájem o využívání produktů
                        </Typography> */}
                        {/* <div style={{ margin: '12px', width: '90%' }}>
                            <Passwordbox onPasswordChange={(p) => { setPassword(p) }} password={password} />
                        </div> */}
                        <Button disabled={email === '' || !interest || !agree} variant='contained' size='large' sx={{ margin: '12px', width: '90%' }} onClick={registerLocal} >{textOdeslano} </Button>
                        {/* <div style={{ alignSelf: 'flex-start', marginLeft: '5%', marginTop: '16px', display: 'flex', fontSize: '12px' }}><p>{t('ForgottenPassword')}</p> <p style={{ textDecoration: 'underline', color: 'red', marginLeft: '2px' }}>{t('ResetPassword')}</p></div>
                        <div style={{ alignSelf: 'flex-start', marginLeft: '5%', marginTop: '16px', display: 'flex', fontSize: '12px' }}><p>{t('DontHaveAccount')}</p> <p style={{ textDecoration: 'underline', color: 'red', marginLeft: '2px' }}>{t('CreateAccount')}</p></div> */}
                        <Typography variant='h5' sx={{ margin: '20px', color: 'white' }}>Jedinečná AKCE! <br /><b style={{ fontSize: '12px' }}>Akce platí do 30. června 2024</b>
                        </Typography>
                        <div>
                            <b style={{ fontWeight: 'bold' }}>
                                <ul>
                                    <li style={{ paddingBottom: '10px', color: '#F34848' }}>3 MĚSÍCE PREMIUM+ ZDARMA</li>
                                    {/* <li style={{ paddingBottom: '10px', color: '#F34848' }}>50% SLEVA NA PŘEDPLATNÉ APPKY</li> */}
                                    {/* <li style={{ paddingBottom: '10px', color: '#F34848' }}>PŘEDBĚŽNÝ PŘÍSTUP DO APLIKACE</li> */}

                                </ul></b>
                        </div>

                    </div>
                </div>
            </div>
        )
    else return (
        <div>
            <div style={{ gridArea: '1/1' }}>
                login
            </div>
            <div style={{ gridArea: '1/1' }}>
                {biggerThan700 &&
                    <img src={layoutPic} style={{ width: '100%', height: 'auto' }} />

                }
                {!biggerThan700 &&
                    <img src={layoutPicMobile} style={{ height: '100%' }} />
                }
            </div>
        </div>
    )
}
