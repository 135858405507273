import axios from "axios";
import { LoveguardClient } from "./ApiServer";

export class LoveguardApiClient {

    
    protected GetApiAddress(): string {
        // console.log(process.env.REACT_APP_API_URL);
        // if (process.env.REACT_APP_API_URL === 'NOT')
        //     return '';
        console.log(process.env.REACT_APP_API_URL);
        return process.env.REACT_APP_API_URL!;
        // return 'https://localhost:7186';
    }
    // public GetApiAddressToPDFViewer(): string {
    //     if (process.env.REACT_APP_API_URL === 'NOT')
    //         return 'https://localhost:5001/todos/api/PDF';
    //     else return process.env.REACT_APP_API_URL! + '/todos/api/PDF';
    // }
    public GetClientToAuth(): LoveguardClient {
        const axiosApiInstance = axios.create();

        // Request interceptor for API calls
        axiosApiInstance.interceptors.request.use(
            async config => {
                // let culture = localStorage.getItem('i18nextLng');
                config.headers.set('TimeZone', Intl.DateTimeFormat().resolvedOptions().timeZone);
                // config.headers.set('accept-language', culture);
                config.headers.Accept = 'application/json';
                config.headers["Content-Type"] = 'application/json';

                return config;
            },
            error => {
                Promise.reject(error)
            });
        axiosApiInstance.interceptors.response.use((response) => {
            return response
        }, async function (error) {
            const originalRequest = error.config;
            if (error.response.status === 401) {
                console.log('401.1', window.location.href);
                var items = window.location.href.split("/");
                window.location.href = window.location.origin + '/' + items[1] + '/' + items[2];
            }
            // if (error.response.status === 401 && !originalRequest._retry) {
            //     try {
            //         if (error.response.headers['is-token-expired'] === 'true') {
            //             console.log('refresh token', error);
            //             originalRequest._retry = true;
            //             await new APIUsersHelper().RefreshToken();
            //             axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('bearer');
            //             return axiosApiInstance(originalRequest);
            //         }
            //         else throw new Error('unathorized but refresh token not expires');
            //     }
            //     catch (e) {
            //         throw new Error('unable to refresh token');
            //     }
            // }
            return Promise.reject(error);
        });
        return new LoveguardClient(this.GetApiAddress(), axiosApiInstance);
    }

    
}