import React from 'react'
import './Login.css';
import layoutPic from '../../Assets/LoveguardBanner7.png'; // with import
import layoutPicMobile from '../../Assets/layoutPictureMobile.png'; // with import
import { useMediaPredicate } from 'react-media-hook';
import LoveguardLogoComponent from '../Shared/LoveguardLogoComponent';
import { Button, TextField, Typography } from '@mui/material';
import FacebookLoginButton from '../FacebookLogin';
import Passwordbox from '../Shared/PasswordBox';
import { LoveguardClient } from '../../Api/ApiServer';
import { LoveguardApiClient } from '../../Api/ApiBaseClient';
import { SuccessResponse } from '@greatsumini/react-facebook-login';
import { useTranslation } from "react-i18next";

interface LoginProps {
    singUp?: boolean;
}

export default function Login(props: LoginProps) {
    const { t } = useTranslation();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const registerFCB = (data: SuccessResponse) => {
        // console.log
        new LoveguardApiClient().GetClientToAuth().registerAndLoginFCB(data.accessToken).then(x => { console.log(x) });
        // new Client
    }
    const registerLocal = () => {
        // console.log
        new LoveguardApiClient().GetClientToAuth().registerLocal(email).then(x => { console.log(x) });
        // new Client
    }
    if (props.singUp === true)
        return (
            <div className="container">
                <div className={biggerThan700 ? 'background-image' : 'background-image-mobile'}>
                    <div className="login-form">
                        <div style={{ marginTop: '10px' }}>
                            <LoveguardLogoComponent />
                        </div>
                        <Typography sx={{ marginTop: '10px' }} variant='h5'>{t('Register')}</Typography>
                        <FacebookLoginButton isRegster onLogin={registerFCB} />
                        <div style={{ display: 'flex', width: '88%', margin: '20px', justifyContent: 'space-around' }}>
                            <div style={{ width: '40%', height: '1px', marginTop: '8px', background: 'red' }}></div>
                            <div style={{ width: '14%', marginLeft: '16px', fontSize: '14px' }}>{t('Or')}</div>
                            <div style={{ width: '40%', height: '1px', marginTop: '8px', background: 'red' }}></div>
                        </div>
                        <TextField value={email} onChange={(e) => { setEmail(e.currentTarget.value) }} label='email' variant='outlined' sx={{ margin: '12px', width: '90%' }} />
                        {/* <div style={{ margin: '12px', width: '90%' }}>
                            <Passwordbox onPasswordChange={(p) => { setPassword(p) }} password={password} />
                        </div> */}
                        <Button variant='contained' size='large' sx={{ margin: '12px', width: '90%' }} onClick={registerLocal}>{t('RegisterViaEmail')}</Button>
                        <div style={{ alignSelf: 'flex-start', marginLeft: '5%', marginTop: '16px', display: 'flex', fontSize: '12px' }}><p>{t('ForgottenPassword')}</p> <p style={{ textDecoration: 'underline', color: 'red', marginLeft: '2px' }}>{t('ResetPassword')}</p></div>
                        <div style={{ alignSelf: 'flex-start', marginLeft: '5%', marginTop: '16px', display: 'flex', fontSize: '12px' }}><p>{t('DontHaveAccount')}</p> <p style={{ textDecoration: 'underline', color: 'red', marginLeft: '2px' }}>{t('CreateAccount')}</p></div>
                    </div>
                </div>
            </div>
        )
    else return (
        <div>
            <div style={{ gridArea: '1/1' }}>
                login
            </div>
            <div style={{ gridArea: '1/1' }}>
                {biggerThan700 &&
                    <img src={layoutPic} style={{ width: '100%', height: 'auto' }} />

                }
                {!biggerThan700 &&
                    <img src={layoutPicMobile} style={{ height: '100%' }} />
                }
            </div>
        </div>
    )
}
