
import { createTheme, Theme, ThemeOptions } from "@mui/material";
const themeOptions: ThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#f34848',
            light: '#f29696',
            dark: '#ca0d1e',
        },
        secondary: {
            main: '#404040',
        },
        background: {
            default: '#F5F5F5',
        },
        text: {
            primary: '#272727',
            secondary: '#272727',
        },
        error: {
            main: '#bb0011',
        },
        success: {
            main: '#2e7d32',
        },
        divider: '#f34848',
    },
    typography: {
        fontFamily: 'Mulish',
        h1: {
            fontFamily: 'Neuton',
        },
        h2: {
            fontFamily: 'Neuton',
        },
        h3: {
            fontFamily: 'Neuton',
        },
        h4: {
            fontFamily: 'Neuton',
        },
        h5: {
            fontFamily: 'Neuton',
        },
        h6: {
            fontFamily: 'Neuton',
        },
    },
 
    shape: {
        borderRadius: 4,
    },
};
export default function CreateLoveguardTheme(): Theme {
    return createTheme(themeOptions)
}