import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import { delay } from 'q';
import { Typography } from '@mui/material';

export default function LoginLoiCompleted() {

  const navigate = useNavigate();
  useEffect(() => {
    // window.open('http://localhost:3001/LoveguardEbook.pdf', "_blank");
    // window.open('https://loveguard.cloud/LoveguardEbook.pdf', "_blank");
    delay(6000).then(() => {
      navigate('/');
    });
  }, []);

  return (
    <div className='app' style={{marginTop: '60px'}}>
      <div className='flexRowClose'><Typography variant='h4'>Díky za registraci! 👍 Až bude App ready, budeš první, komu dáme vědět!</Typography><br /></div>
      <div className='flexRowClose'><Typography variant='body1'>Nyní tě přesměrujeme na hlavní stránku</Typography></div>
    </div>
  )
}
