import { Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult, OnDragEndResponder } from 'react-beautiful-dnd';
import Navbar from '../Navigation/Navbar';
import './QuizOrderLovePriorities.css';
import { AccessTimeOutlined, CardGiftcardOutlined, CleaningServicesOutlined, KeyboardVoiceOutlined, TouchAppOutlined } from '@mui/icons-material';
import { minWidth } from '@mui/system';
import sluzba1x1 from '../../Assets/sluzba.png';
import cas1x1 from '../../Assets/cas.png';
import dotek1x1 from '../../Assets/dotek.png';
import darek1x1 from '../../Assets/darek.png';
import pochvala1x1 from '../../Assets/slova.png';
import { useMediaPredicate } from 'react-media-hook';
import { useTranslation } from "react-i18next";

export interface Item {
  id: string;
  text: string;
  languageTypeId: number;
}
interface QuizOrderLovePrioritiesProps {
  onNext: (result: Item[]) => void;
}
const QuizOrderLovePriorities = (props: QuizOrderLovePrioritiesProps) => {
  const { onNext } = props;
  const { t } = useTranslation();
  // const {t} = useTranslation();
  const [items, setItems] = React.useState<Item[]>([
    { id: '1', text: t('Touch'), languageTypeId: 1 },
    { id: '2', text: t('Gift'), languageTypeId: 2 },
    { id: '3', text: t('Time'), languageTypeId: 3 },
    { id: '4', text: t('Praise'), languageTypeId: 4 },
    { id: '5', text: t('Service'), languageTypeId: 5 },
  ]);

  useEffect(() => {
    // Posune stránku na začátek při každém renderování komponenty
    window.scrollTo(0, 0);
  }, []);

  const biggerThan700 = useMediaPredicate("(min-width: 700px)");

  const onDragEnd: OnDragEndResponder = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const itemsCopy = Array.from(items);
    const [reorderedItem] = itemsCopy.splice(result.source.index, 1);

    itemsCopy.splice(result.destination.index, 0, reorderedItem);
    setItems(itemsCopy);
  };

  return (
    <div>

      <div style={{ background: '#F5F5F5', padding: '4em 2em 0em 2em' }}><Typography variant='h3' sx={{ paddingBottom: '12px' }}>{t('DoYouKnowYourPrimaryLoveType')}</Typography>
        <Typography>{t('QuizOrderKnowYourLoveTypeInAFewMinutes')}</Typography>
        <Typography variant='h4' sx={{ marginTop: '20px', marginBottom: '-20px' }}>{t('5TypesOfLove')}</Typography>
      </div>

      <div className='flexRow2'>
        <div className='cardPictureQuiz' style={{ backgroundImage: `url(${cas1x1})`, backgroundSize: 'cover', maxWidth: biggerThan700 ? '280px' : '348px' }}>
          <div className='cardTransparentInnerQuiz'>
            <Typography variant='h4' color='primary'><AccessTimeOutlined sx={{ marginRight: '8px' }}></AccessTimeOutlined>{t('Time')}</Typography>
            <div><Typography variant='body1'>{t('TimeQuizOrderDescriptionText')}</Typography>
            </div>
          </div>
        </div>

        <div className='cardPictureQuiz' style={{ backgroundImage: `url(${darek1x1})`, backgroundSize: 'cover', maxWidth: biggerThan700 ? '280px' : '348px' }}>
          <div className='cardTransparentInnerQuiz'>
            <Typography variant='h4' color='primary'><CardGiftcardOutlined sx={{ marginRight: '8px' }}></CardGiftcardOutlined>{t('Gift')}</Typography>
            <div><Typography variant='body1'>{t('GiftQuizOrderDescriptionText')}</Typography>
            </div>
          </div>
        </div>

        <div className='cardPictureQuiz' style={{ backgroundImage: `url(${dotek1x1})`, backgroundSize: 'cover', maxWidth: biggerThan700 ? '280px' : '348px' }}>
          <div className='cardTransparentInnerQuiz'>
            <Typography variant='h4' color='primary'><TouchAppOutlined sx={{ marginRight: '8px' }}></TouchAppOutlined>{t('Touch')}</Typography>
            <div><Typography variant='body1'>{t('TouchQuizOrderDescriptionText')}</Typography>
            </div>
          </div>
        </div>

        <div className='cardPictureQuiz' style={{ backgroundImage: `url(${pochvala1x1})`, backgroundSize: 'cover', maxWidth: biggerThan700 ? '280px' : '348px' }}>
          <div className='cardTransparentInnerQuiz'>
            <Typography variant='h4' color='primary'><KeyboardVoiceOutlined sx={{ marginRight: '8px' }}></KeyboardVoiceOutlined>{t('Praise')}</Typography>
            <div><Typography variant='body1'>{t('PraiseQuizOrderDescriptionText')}</Typography>
            </div>
          </div>
        </div>

        <div className='cardPictureQuiz' style={{ backgroundImage: `url(${sluzba1x1})`, backgroundSize: 'cover', maxWidth: biggerThan700 ? '280px' : '348px' }}>
          <div className='cardTransparentInnerQuiz'>
            <Typography variant='h4' color='primary'><CleaningServicesOutlined sx={{ marginRight: '8px' }}></CleaningServicesOutlined>{t('Service')}</Typography>
            <div><Typography variant='body1'>{t('ServiceQuizOrderDescriptionText')}</Typography>
            </div>
          </div>
        </div>

      </div>
      <div className='flexRow3' style={{ padding: '2em 1em 1em 2em' }}><Typography variant='h5' color='primary'>{t('ChangeOrgerOfLoveTypes')}</Typography>
      </div>



      <div className='flexRow3'>
        <div >
          <ol style={{ paddingRight: '1em' }}>
            <li style={{ height: '50px', marginTop: '24px' }}></li>
            <li style={{ height: '50px', marginTop: '24px' }}></li>
            <li style={{ height: '50px', marginTop: '24px' }}></li>
            <li style={{ height: '50px', marginTop: '24px' }}></li>
            <li style={{ height: '50px', marginTop: '24px' }}></li>
          </ol>
        </div>
        <div style={{ width: biggerThan700 ? '640px' : '268px' }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="items">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="items-container" >
                  {items.map(({ id, text }, index) => (

                    <Draggable key={id} draggableId={id} index={index} >
                      {(provided) => (
                        <div style={{ borderRadius: '16px' }}
                          className="item"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          draggable={true}
                        >
                          <div className="handle" style={{ height: '48px', padding: '12px' }}>&#x2630;</div>
                          <div className="text">{text}</div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>

      <div className='flexRow3' style={{ padding: '2em' }}><Button variant='contained' onClick={e => {
        console.log(items);
        onNext(items);

      }} size='large' sx={{ padding: '2em', minWidth: '332px', maxHeight: '6NPM0px', textAlign: 'center' }}>{t('Continue')}</Button>
      </div>
    </div>

  );
};

export default QuizOrderLovePriorities;
