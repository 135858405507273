import React from 'react'
import Navbar from '../Navigation/Navbar';
import '../../Components/Styles/MainScene.css';
import { Button, Grid, Typography, useTheme, Paper } from '@mui/material';
import { useMediaPredicate } from 'react-media-hook';
import MainBgMobile from '../../Assets/layoutPictureMobile.png';
import motor from '../../Assets/motor.png';
import imgKlic from '../../Assets/imgKlic.png';
import imgOdkazZdarma from '../../Assets/imgOdkazZdarma.png';
import pripomene from '../../Assets/pripomene.png';
import imgPenize from '../../Assets/imgPenize.png';
import slevaLoi from '../../Assets/SlevaLoi.png';
import imgWomanThrone from '../../Assets/womanThrone.png';
import imgManDataMobile from '../../Assets/manDataMobile.png';
import imgSdilejNas from '../../Assets/imgSdilejNas.png';
import manHatMobile from '../../Assets/manHatMobile.png';
import appgif from '../../Assets/Appgif.gif';
import { EmojiEmotions, NotificationsActiveOutlined } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { t } from 'i18next';
import PropTypes from "prop-types";

function MainScene() {
    const { t } = useTranslation();
    const theme = useTheme();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

}

export default function Team() {
    return (
        <div style={{height: '100%'}}>
            <iframe src="https://gamma.app/embed/jkpvcdo9sj6mtud" style={{width: '100%  ', maxWidth: '100%', height: '100%'}} allow="fullscreen" title="Potřebujete vývojový tým? Náš tým je tu pro vás!"></iframe>
        </div>
    )
}
