import React from 'react'

export default function PrivacyEn() {
    return (
        <div>
            <p>Last updated: 5/30/2023</p>

            <p>Thank you for using our application for advice and tasks in relationships between men and women. This document describes how we collect, process, and store your personal data in relation to our application. Please read the following information carefully.
            </p>
            <p>Collection of Personal Data
            When using our application, we may collect the following types of personal data from you:
            </p>
            <p>1.1. Information provided through Facebook Login: When using Facebook Login, we only collect your publicly available information permitted by Facebook. This includes your public profile picture, name, and email address.
            </p>
            <p>1.2. Voluntarily provided information: During the use of the application, you may voluntarily provide additional personal data, such as photographs, names of individuals in your circle, information about your relationships, and similar details.
            </p>
            <p>Processing and Use of Personal Data
            Your personal data is processed and used for the following purposes:</p>

            <p>2.1. Provision of Services: Your personal data is used to provide advice and tasks related to relationships between men and women within our application.
            </p>
            <p>.2. Personalization: With your consent, we may use your personal data to personalize content and offers within our application.</p>

            <p>2.3. Communication: We may send you information about our application, news, updates, and relevant offers based on your consent.</p>

            <p>Sharing of Personal Data</p>
            <p>We will not sell, rent, or share your personal data with third parties, except in the following situations:</p>

            <p>3.1. Collaborating Partners: We may share your personal data with our collaborating partners who assist us in providing our services, but only to the extent necessary to fulfill their tasks.
            </p>
            <p>3.2. Legal Requirements: If required by law or regulations, we may be obligated to share your personal data with authorized authorities.
            </p>
            <p>Security
            We implement reasonable measures to ensure the security of your personal data and protect against unauthorized access, loss, misuse, or damage.
            </p>
            <p>Retention of Personal Data
            We will retain your personal data for the period necessary to fulfill the purposes stated in this policy. After the expiration of this period, your personal data will be deleted or anonymized unless otherwise specified.
            </p>
            <p>Your Rights
            You have the right to request access to your personal data, correct it, restrict its processing, or delete it, where applicable under relevant laws and regulations.
            </p>
            <p>Contact
            If you have any questions, comments, or requests regarding our Privacy Policy or the handling of your personal data, please contact us using the information provided below:
            </p>

            forman@loveguard.cloud

        </div>
    )
}
