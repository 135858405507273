import React, { useEffect, useState } from 'react'
import QuizEntryInfo from './QuizEntryInfo';
import QuizMatchScene from './QuizMatchScene';
import QuizOrderLovePriorities, { Item } from './QuizOrderLovePriorities';
import { Typography } from '@mui/material';
import Navbar from '../Navigation/Navbar';

export default function QuizMainScene() {
    const raiseStep = () => {
        setStep(step + 1);
    }
    const [orderLanguages, setOrderLanguages] = useState<Item[] | null>(null);
    const [step, setStep] = useState(0);
    const [succesors, setSuccesors] = useState<any[]>([]);
    const [loosers, setLoosers] = useState<any[]>([]);
    useEffect(() => {
        raiseStep();
    }, [orderLanguages]);
    return (
        <div>
            <Navbar  />

            {step === 0 && <QuizEntryInfo onNext={raiseStep} />}
            {step === 1 && <QuizOrderLovePriorities onNext={(items) => { setOrderLanguages(items); }} />}
            {step === 2 && <QuizMatchScene dataLanguages={orderLanguages} />}
        </div>)
}
