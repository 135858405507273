import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FacebookLogin, { SuccessResponse } from '@greatsumini/react-facebook-login';
import { Facebook } from '@mui/icons-material';
export interface FacebookLoginButtonProps {
    onLogin: (data: SuccessResponse) => void;
    isRegster: boolean;
    data?: any;
}
export default function FacebookLoginButton(props: FacebookLoginButtonProps, ref: any) {
    const { data, onLogin,isRegster } = props;
    const { t } = useTranslation();

    // const [open, setOpen] = React.useState(false);



    return (

        <FacebookLogin
            appId="769511551229475"
            onSuccess={(response) => {
                console.log('Login Success!', response);
                onLogin(response);
            }}
            onFail={(error) => {
                console.log('Login Failed!', error);
            }}
            onProfileSuccess={(response) => {
                console.log('Get Profile Success!', response);
            }}
            render={({ onClick, logout }) => (
                <div>
                    <div className='loginButton' onClick={onClick} >
                        <Facebook style={{ color: 'white' }} />
                        <p className='fb-text'>{isRegster ? t('RegisterFacebook'):t('SignInFacebook')}</p>
                    </div>
                    {/* <Button onClick={() => { if (logout) logout(()=>{
                        console.log('logout');
                    }); }} >logout</Button> */}
                </div>
            )}

        />);
}