import React from 'react'
import '../../Components/Styles/MainScene.css';
import { Button, Grid, Typography, useTheme, Paper } from '@mui/material';
import { useMediaPredicate } from 'react-media-hook';
import { useTranslation } from "react-i18next";
import { t } from 'i18next';
import PropTypes from "prop-types";
import PitchDeck from '../../Assets/PitchDeck.png';
import LeanCanvas from '../../Assets/LeanCanvas.png';
import Market from '../../Assets/VelikostTrhu.png';
import FinancialPlan from '../../Assets/FinPlan.png';
import App from '../../Assets/PrototypApp.png';
import TermSheet from '../../Assets/TermSheet.png';
import LogoText from '../../Assets/LogoNapis.png';
import { useNavigate } from 'react-router-dom';
import { Height } from '@mui/icons-material';


export default function InvestorSummary() {
    const { t } = useTranslation();
    const theme = useTheme();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const navigate = useNavigate();
    return (
        <div style={{minHeight: '100%', background:'#1F1F1F' }}>
            <div style={{color: 'white', backgroundColor: '#1F1F1F', textAlign: 'center'}}>
                <div onClick={() => { navigate('/') }} style={{ cursor:'pointer' }}>
                <img src={LogoText} width='260px' style={{ marginTop: '20px' }}></img>
                </div>
                <Typography variant='h5' fontWeight= 'bold' >START-UP SUMMARY</Typography>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: biggerThan700 ? '20px 240px' : '12px', backgroundColor: '#1F1F1F' }}>

                <div onClick={() => window.open('https://pitch.com/public/b6dc5496-8eed-4d22-bf20-6d059a96c2df', '_blank')} style={{ minWidth: '344px', height: '176px', backgroundColor: '#2F2F2F', borderRadius: '8px', textAlign: 'left', marginBottom: '24px', marginRight: biggerThan700 ? '24px' : '0px', boxShadow: '0px 0px 8px 0px #F34848', cursor:'pointer' }}>
                    <Typography variant='h5' color='#F34848' margin='10px' marginLeft='20px' fontWeight='bold'>
                        PITCH DECK
                    </Typography>
                    <div style={{ backgroundImage: `url(${PitchDeck})`, backgroundSize: 'cover', height: '124px', width: '344px', borderRadius: '0px 0px 8px 8px' }}>
                        <div className='cardTransparentInnerFunnel'>

                        </div>
                    </div>
                </div>
                <div onClick={() => window.open('https://loveguard-my.sharepoint.com/:i:/g/personal/forman_loveguard_cloud/EXo7Bmxtfo5FgQoXW-KvFvYBl95uJdMCxPWccJrL9Iirzw?e=kMVH3c', '_blank') } style={{ minWidth: '344px', height: '176px', backgroundColor: '#2F2F2F', borderRadius: '8px', textAlign: 'left', marginBottom: '24px', marginRight: biggerThan700 ? '24px' : '0px', boxShadow: '0px 0px 8px 0px #F34848' , cursor:'pointer' }}>
                    <Typography variant='h5' color='#F34848' margin='10px' marginLeft='20px' fontWeight='bold'>
                        LEAN CANVAS
                    </Typography>
                    <div style={{ backgroundImage: `url(${LeanCanvas})`, backgroundSize: 'cover', height: '124px', width: '344px', borderRadius: '0px 0px 8px 8px' }}>
                        <div className='cardTransparentInnerFunnel'>

                        </div>
                    </div>
                </div>
                <div onClick={() => window.open('https://loveguard-my.sharepoint.com/:i:/g/personal/forman_loveguard_cloud/EaFVSOV3XHJCv8P4YFYMa9UBQDiax1_75ZudGfYfsWZaEw?e=nX402Y',  '_blank')} style={{ minWidth: '344px', height: '176px', backgroundColor: '#2F2F2F', borderRadius: '8px', textAlign: 'left', marginBottom: '24px', marginRight: biggerThan700 ? '24px' : '0px', boxShadow: '0px 0px 8px 0px #F34848' , cursor:'pointer' }}>
                    <Typography variant='h5' color='#F34848' margin='10px' marginLeft='20px' fontWeight='bold'>
                        MARKET
                    </Typography>
                    <div style={{ backgroundImage: `url(${Market})`, backgroundSize: 'cover', height: '124px', width: '344px', borderRadius: '0px 0px 8px 8px' }}>
                        <div className='cardTransparentInnerFunnel'>

                        </div>
                    </div>
                </div>
                <div onClick={() => window.open('https://loveguard-my.sharepoint.com/:x:/g/personal/forman_loveguard_cloud/EUeSrwRSU2ZGiFgGuDk4i_QB4uPPvlkw4LrVPatLfZfmvw?e=DHtx0M', '_blank',)} style={{ minWidth: '344px', height: '176px', backgroundColor: '#2F2F2F', borderRadius: '8px', textAlign: 'left', marginBottom: '24px', marginRight: biggerThan700 ? '24px' : '0px', boxShadow: '0px 0px 8px 0px #F34848' , cursor:'pointer' }}>
                    <Typography variant='h5' color='#F34848' margin='10px' marginLeft='20px' fontWeight='bold'>
                        FINANCIAL PLAN
                    </Typography>
                    <div style={{ backgroundImage: `url(${FinancialPlan})`, backgroundSize: 'cover', height: '124px', width: '344px', borderRadius: '0px 0px 8px 8px' }}>
                        <div className='cardTransparentInnerFunnel'>

                        </div>
                    </div>
                </div>
                <div onClick={() => window.open('https://www.figma.com/proto/r8rFU53jXviD7aE6EDagO4/Loveguard?page-id=1618%3A104175&type=design&node-id=1618-105423&viewport=3596%2C1154%2C1.06&t=MohpZchaEuulTbVA-1&scaling=min-zoom&starting-point-node-id=1618%3A105423&mode=design', '_blank') } style={{ minWidth: '344px', height: '176px', backgroundColor: '#2F2F2F', borderRadius: '8px', textAlign: 'left', marginBottom: '24px', marginRight: biggerThan700 ? '24px' : '0px', boxShadow: '0px 0px 8px 0px #F34848' , cursor:'pointer' }}>
                    <Typography variant='h5' color='#F34848' margin='10px' marginLeft='20px' fontWeight='bold'>
                        PROTOTYP APP
                    </Typography>
                    <div style={{ backgroundImage: `url(${App})`, backgroundSize: 'cover', height: '124px', width: '344px', borderRadius: '0px 0px 8px 8px' }}>
                        <div className='cardTransparentInnerFunnel'>

                        </div>
                    </div>
                </div>
                <div onClick={() => window.open('https://loveguard-my.sharepoint.com/:w:/g/personal/forman_loveguard_cloud/EcIHrRXAlFNNgh81_OF17bQBqOEVwo_9mkp_fCNFqDRxAQ?e=TANkQ8', '_blank') } style={{ minWidth: '344px', height: '176px', backgroundColor: '#2F2F2F', borderRadius: '8px', textAlign: 'left', marginBottom: '24px', marginRight: biggerThan700 ? '24px' : '0px', boxShadow: '0px 0px 8px 0px #F34848' , cursor:'pointer' }}>
                    <Typography variant='h5' color='#F34848' margin='10px' marginLeft='20px' fontWeight='bold'>
                        TERM SHEET
                    </Typography>
                    <div style={{ backgroundImage: `url(${TermSheet})`, backgroundSize: 'cover', height: '124px', width: '344px', borderRadius: '0px 0px 8px 8px' }}>
                        <div className='cardTransparentInnerFunnel'>

                        </div>
                    </div>
                </div>



            </div>
        </div>
    )
}
