import React from 'react'
import Navbar from '../Navigation/Navbar';
import '../../Components/Styles/MainScene.css';
import { Button, Grid, Typography, useTheme, Paper } from '@mui/material';
import { useMediaPredicate } from 'react-media-hook';
import MainBgMobile from '../../Assets/layoutPictureMobile.png';
import motor from '../../Assets/motor.png';
import imgKlic from '../../Assets/imgKlic.png';
import imgOdkazZdarma from '../../Assets/imgOdkazZdarma.png';
import pripomene from '../../Assets/pripomene.png';
import imgPenize from '../../Assets/imgPenize.png';
import slevaLoiMobile from '../../Assets/SlevaLoiMobile.png';
import imgWomanThrone from '../../Assets/womanThrone.png';
import imgManDataMobile from '../../Assets/manDataMobile.png';
import imgSdilejNas from '../../Assets/imgSdilejNas.png';
import manHatMobile from '../../Assets/manHatMobile.png';
import appgif from '../../Assets/Appgif.gif';
import { EmojiEmotions, NotificationsActiveOutlined } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { t } from 'i18next';
import PropTypes from "prop-types";

function MainScene() {
    const { t } = useTranslation();
    const theme = useTheme();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");

}

export default function LoI() {
    return (
        <div className='app'>
            <Navbar />
            <div className='flexRowClose' style={{ marginTop: '48px' }}>

                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${imgPenize})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold', fontSize: '28px' }}>Jedna věc, kterou víme jistě, je, že na vztazích záleží. 🤝<br />  <b style={{ color: '#FFFFFFde' }}>Chceš vidět, jak i ty dokážeš zlepšit vztah s partnerkou o několik úrovní výš?</b></Typography>
                            <Typography variant='body1' sx={{ padding: '20px 20px 20px 20px', height: 'auto' }}>Dáme ti:<b style={{ fontWeight: 'bold' }}>
                                <ul style={{ paddingLeft: '20px', paddingBottom: '20px', paddingTop: '12px' }}>
                                    <li style={{ color: '#F34848' }}>do rukou nástroje 📱</li>
                                    <li style={{ color: '#F34848' }}>do hlavy znalosti 🧠</li>
                                    <li style={{ color: '#F34848' }}>k mání služby 🥂</li>

                                </ul></b>
                                díky kterým si vybuduješ pohodový vztah plný péče, porozumění a sexu.
                            </Typography>

                        </div>
                    </div>
                </div>

                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${imgSdilejNas})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '20px', paddingBottom: '40px' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '0px' }}>
                            <Button variant='contained' sx={{ minWidth: '90%', minHeight: '80px', fontSize: '16px' }} href='/signuploi'>ZAREGISTROVAT</Button>
                        </div>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold', fontSize: '28px' }}>Nepotřebuješ vědět víc a jdeš do toho? 💪<br /> <b style={{ color: '#FFFFFFde' }}>Tak klikni a jdeme na to!</b></Typography>
                            <Typography variant='body1' sx={{ padding: '20px 20px 20px 20px', height: 'auto' }}> Je to zdarma, jedná se o <b style={{ color: '#F34848' }}>pouhé potvrzení tvého zájmu</b>. Jakmile bude vše ready, tak budeš mít aplikaci k dispozici jako první před všemi ostatními! <br />Do té doby se můžeš nechat inspirovat v seberozvoji na <a href='https://www.facebook.com/profile.php?id=100089619233058' style={{ color: '#F34848' }}>našem Facebooku</a>. 😉
                            </Typography>
                        </div>

                    </div>
                </div>

                <div>
                    <Typography variant='h4' sx={{ color: '#F34848', fontWeight: 'bold', marginLeft: '-36px', marginTop: '20px' }}>Zaujalo tě to, a proto bys chtěl vědět, jak a s čím ti píchnem?</Typography>
                </div>

                <div style={{ display: 'flex', justifyContent: 'left', marginLeft: '-120px' }}>
                    <Typography variant='h4' sx={{ color: '#272727', fontWeight: 'bold', marginBottom: '20px' }}>Račte vstoupit!</Typography>
                </div>

                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${imgKlic})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold', fontSize: '28px' }}>Než se vrhneme dál, tak si odpověz na následující otázky 🕵️‍♂️</Typography>
                            <Typography variant='body1' sx={{ padding: '20px 20px 20px 20px', height: 'auto' }}><b style={{ fontWeight: 'bold' }}>
                                <ul style={{ paddingLeft: '20px' }}>
                                    <li style={{ paddingBottom: '10px' }}>Dal jsi jí kytku za poslední kvartál?</li>
                                    <li style={{ color: '#F34848', paddingBottom: '10px' }}>Pochválil jsi ji tento měsíc?</li>
                                    <li style={{ paddingBottom: '10px' }}>Poděkoval jsi jí za věci, které pro tebe dělá?</li>
                                    <li style={{ color: '#F34848', paddingBottom: '10px' }}>Vezmeš ji na večeři ve dvou alespoň 1x do roka?</li>
                                    <li style={{ paddingBottom: '10px' }}>Předehra před sexem ti není cizí?</li>
                                    <li style={{ color: '#F34848', paddingBottom: '10px' }}>Hádáte se pouze výjimečně?</li>
                                    <li style={{ paddingBottom: '10px' }}>Bavíte se spolu o důležitých životních otázkách?</li>
                                    <li style={{ color: '#F34848', paddingBottom: '10px' }}>Nezapomínáš na výročí a narozeniny a vždy víš, co pořídit?</li>
                                    <li style={{ paddingBottom: '10px' }}>Chápeš menstruační cyklus a víš, jak se zachovat v každé jeho fázi?</li>
                                    <li style={{ color: '#F34848', paddingBottom: '10px' }}>Seberozvoj pro tebe není sprostý slovo?</li>

                                </ul></b>
                            </Typography>

                        </div>
                    </div>
                </div>
                <div>
                    <Typography variant='body1' sx={{ margin: '0px -10px 40px -20px' }}><br />Pokud jsi na všechno odpověděl ANO, tak je vidět, že to máš zmáknutý a zlepšovat se nepotřebuješ. Ledaže... 👨‍🔧<br />

                    </Typography>
                    <Button variant='contained' sx={{ minWidth: '90%', minHeight: '60px', fontSize: '16px', margin: '0px 0px 40px 12px' }} href='/signuploi'>ZAREGISTROVAT
                    </Button>
                </div>
                <Typography variant='body1' sx={{ margin: '0px -10px 40px -20px' }}>
                    <b style={{ fontWeight: 'bold' }}>Jestli si upřímně myslíš, že by sis dokázal představit váš vztah lepší, tak ČTI DÁL. 👇</b>
                </Typography>

                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${manHatMobile})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold', fontSize: '28px' }}>Vidíš se tady někde? 👀</Typography>
                            <Typography variant='body1' sx={{ padding: '0px 20px 20px 20px', height: 'auto' }}><b style={{ fontWeight: 'bold' }}>
                                <ul style={{ padding: '20px' }}>
                                    <li style={{ paddingBottom: '10px' }}>Chci být ve vztahu více spokojený</li>
                                    <li style={{ color: '#F34848', paddingBottom: '10px' }}>Chci méně hádek a nepochopení</li>
                                    <li style={{ paddingBottom: '10px' }}>Chci více sexu</li>
                                    <li style={{ color: '#F34848', paddingBottom: '10px' }}>Chci budovat lepší vztah</li>
                                    <li style={{ paddingBottom: '10px' }}>Chci pracovat na svém seberozvoji</li>
                                    <li style={{ color: '#F34848', paddingBottom: '10px' }}>Nechci mít doma dusno</li>
                                    <li style={{ paddingBottom: '10px' }}>Chci, aby si mě partnerka více vážila</li>
                                    <li style={{ color: '#F34848', paddingBottom: '10px' }}>Chci pochopit, proč mi to s partnerkou nefunguje</li>
                                    <li style={{ paddingBottom: '10px' }}>Chci žít spokojenější život</li>
                                    <li style={{ color: '#F34848', paddingBottom: '10px' }}>Chci, aby moji potomci vyrůstali ve funkční rodině</li>
                                    <li style={{ paddingBottom: '10px' }}>Chci, aby mě partnerka uznávala</li>
                                    <li style={{ color: '#F34848', paddingBottom: '10px' }}>Chci zlepšit své psychické zdraví</li>
                                </ul></b>
                            </Typography>

                        </div>
                    </div>
                </div>

                <div>
                    <Typography variant='h4' sx={{ color: '#272727', fontWeight: 'bold', marginLeft: '-36px', marginTop: '20px' }}>Chceš vědět, jak tyhle <b style={{ color: '#F34848' }}>přání přeměnit na příležitosti</b> a začít si to užívat? 🦸‍♂️</Typography>
                </div>

                <Typography variant='body1' sx={{ margin: '20px -20px 20px -36px' }}>Není to žádná magie, i když to tak může vypadat 🧙. Už na začátku jsem zmiňoval, že kouzlo spočívá v kombinaci
                    <b style={{ fontWeight: 'bold' }}>
                        <ul style={{ padding: '20px' }}>
                            <li>Appky</li>
                            <li>Znalostí</li>
                            <li>Služeb</li>
                        </ul></b>

                </Typography>


                <div style={{ marginTop: '20px' }}>
                    <Typography variant='h4' sx={{ color: '#F34848', fontWeight: 'bold', marginLeft: '-164px' }}>Aplikace</Typography>
                </div>
                <Typography variant='body1' sx={{ margin: '0px -20px 20px -36px' }}><br />
                    <b style={{ fontWeight: 'normal' }}><b style={{ fontWeight: 'bold' }}>Plníš výzvy</b> pro jednotlivé typy lásky, od primárního k sekundárním, podle toho, jak tvé partnerce vyjde <b style={{ color: '#F34848' }}>📊 Kvíz lásky </b>.<br /><br />Dáme ti nástroje, díky kterým to zvládneš a budeš to mít vyřešený. 👌
                        <b style={{ fontWeight: 'bold' }}>
                            <ul style={{ padding: '20px' }}>
                                <li style={{ color: '#F34848' }}>✅ Výzvy</li>
                                <li style={{ color: '#F34848' }}>📅 Kalendář</li>
                                <li style={{ color: '#F34848' }}>👫 Profily</li>

                            </ul></b></b>
                </Typography>

                <div style={{ marginTop: '20px', marginBottom: '40px' }}>
                    <img src={appgif}></img>
                </div>


                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${imgManDataMobile})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold', }}>Znalosti</Typography>
                            <Typography variant='body1' sx={{ padding: '0px 20px 20px 20px', height: 'auto' }}><b style={{ fontWeight: 'bold' }}>
                                <ul style={{ padding: '20px' }}>
                                    <li style={{ paddingBottom: '10px' }}><a href='https://www.facebook.com/groups/804023694343382' style={{ color: '#F34848' }}>Facebook Komunita</a> chlapů na obdobný seberozvojový vlně</li>
                                    <li style={{ paddingBottom: '10px' }}><a href='https://www.facebook.com/profile.php?id=100089619233058' style={{ color: '#F34848' }}>Facebook Stránka</a></li>
                                    <li style={{ paddingBottom: '10px' }}>Chlapský návody na ženský problémy</li>
                                    <li style={{ paddingBottom: '10px' }}>Kurzy</li>
                                </ul></b>
                            </Typography>

                        </div>
                    </div>
                </div>

                <div className='cardFunnelMobile'>
                    <div style={{ backgroundImage: `url(${imgWomanThrone})`, backgroundSize: 'cover', borderRadius: '16px', paddingTop: '40px', paddingBottom: '40px' }}>
                        <div className='cardTransparentInnerFunnel'>
                            <Typography variant='h4' sx={{ padding: '20px 20px 0px 20px', color: '#F34848', fontWeight: 'bold' }}>Služby</Typography>
                            <Typography variant='body1' sx={{ padding: '0px 20px 20px 20px', height: 'auto' }}><b style={{ fontWeight: 'bold' }}>
                                <ul style={{ padding: '20px' }}>
                                    <li style={{ paddingBottom: '10px' }}>Pošlem ti dárek kam budeš chtít</li>
                                    <li style={{ paddingBottom: '10px' }}>Konzultace se vztahovým koučem</li>
                                    <li style={{ paddingBottom: '10px' }}>Naplánujeme ti událost</li>

                                </ul></b>
                            </Typography>

                        </div>
                    </div>
                </div>

                <div>
                    <Typography variant='h4' sx={{ color: '#272727', fontWeight: 'bold', marginLeft: '-36px', marginTop: '40px', marginBottom: '60px' }}>Připoj se k <b style={{ color: '#F34848' }}>LOVEGUARD</b>, <br />pokud tak jako my věříš, že <b style={{ color: '#F34848' }}>na vztazích záleží</b>, a že si dokážeme své bytí zlepšit.</Typography>
                </div>

                <Button variant='contained' sx={{ minWidth: '340px', minHeight: '100px', fontSize: '20px' }} href='/signuploi'>
                    👉 ZAREGISTROVAT 👈
                </Button>

                <Typography variant='h5' sx={{ margin: '20px -20px 20px -36px' }}>Jedinečná AKCE!<br /><b style={{ fontSize: '12px' }}>Akce platí do 14.02.2024.</b>
                </Typography>

                <div>
                    <b style={{ fontWeight: 'bold' }}>
                        <ul>
                            <li style={{ paddingBottom: '10px', color: '#F34848' }}>3 MĚSÍCE PREMIUM ZDARMA</li>
                            <li style={{ paddingBottom: '10px', color: '#F34848' }}>50% SLEVA NA PŘEDPLATNÉ APPKY</li>
                            <li style={{ paddingBottom: '10px', color: '#F34848' }}>PŘEDBĚŽNÝ PŘÍSTUP DO APLIKACE</li>

                        </ul></b>
                </div>
                <div>
                    <img src={slevaLoiMobile} style={{ maxHeight: '100%'}}></img>
                </div>
                <div style={{ marginTop: '-120px', color: 'white' }}>
                    <Typography variant='h3'>Tak jak? ;) 🤙</Typography>
                </div>




            </div>
        </div>
    )
}
