import { Button, Typography } from '@mui/material'
import React from 'react'

interface QuizEntryInfoProps {
    onNext: () => void;
}
export default function QuizEntryInfo(props: QuizEntryInfoProps) {
    const { onNext } = props;
    return (
        <div>
            <Typography>Zahájení</Typography>
            <Button onClick={() => { onNext(); }}>Dál</Button>
        </div>
    )
}
