import React, { useState, useEffect } from 'react';
import Navbar from './Navigation/Navbar';
import layoutPic from '../Assets/TestBackgroundPicture.png'; // with import
import layoutPicMobile from '../Assets/TestBackgroundPictureMobile.png'; // with import
import '../Components/Styles/MainScene.css';
import { useTranslation } from "react-i18next";
import { Button, Grid, Typography, useTheme, Paper, MobileStepper, Checkbox } from '@mui/material';
import Carousel from '../Components/Carousel/Carousel';
import sluzba from '../Assets/sluzba.png';
import cas from '../Assets/cas.png';
import dotek from '../Assets/dotek.png';
import darek from '../Assets/darek.png';
import kompliment from '../Assets/slova.png';
import Review1 from '../Assets/review1.png';
import Review2 from '../Assets/review2.png';
import Review3 from '../Assets/review3.png';
import Lukas from '../Assets/LukasKluch.jpg';
import { useMediaPredicate } from 'react-media-hook';
import { AccessTimeFilledOutlined, AccessTimeOutlined, AlignHorizontalRight, Cancel, CancelOutlined, CardGiftcardOutlined, Check, CleaningServicesOutlined, DoneAllOutlined, FacebookOutlined, HandymanOutlined, Height, Instagram, KeyboardVoiceOutlined, LinkedIn, NotificationsActiveOutlined, PhoneIphone, PlayCircleFilledOutlined, PlayCircleOutline, PlaylistAddCheckOutlined, Quiz, QuizOutlined, SaveAlt, SquareFootOutlined, StackedBarChartOutlined, StarPurple500, StarPurple500Rounded, StarRateOutlined, StarRateSharp, StarRateTwoTone, StraightenOutlined, SupportOutlined, TouchAppOutlined, Twitter, YouTube } from '@mui/icons-material';
import { alignProperty } from '@mui/material/styles/cssUtils';
import Item from './Carousel/Item';
import personalised from '../Assets/PersonalisedPicture.png';
import clear from '../Assets/ClearPicture.png';
import practical from '../Assets/PracticalPicture.png';
import pripravi from '../Assets/pripravi.png';
import motor from '../Assets/motor.png';
import { useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import mobilscreen from '../Assets/Mobilscreen.png';
import novaVyzva from '../Assets/SuggestionPicture.png';
import prijetiVyzvy from '../Assets/CompletePicture.png';
import splneniVyzvy from '../Assets/RatingPicture.png';
import stavLasky from '../Assets/EmptyPicture.png';
import DashboardPic from '../Assets/DashboardPic.png';
import ChallengesPic from '../Assets/ChallengesPic.png';
import PlannerPic from '../Assets/PlannerPic.png';
import StorePic from '../Assets/StorePic.png';
import WishlistPic from '../Assets/WishlistPic.png';
import PartnerPic from '../Assets/PartnerPic.png';
import { padding } from '@mui/system';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { relative } from 'path';


const tutorialSteps = [
    {
        label: 'Obrázek 1',
        imgPath:
            DashboardPic,
    },
    {
        label: 'Obrázek 2',
        imgPath:
            PlannerPic,
    },
    {
        label: 'Obrázek 3',
        imgPath:
            ChallengesPic,
    },
    {
        label: 'Obrázek 4',
        imgPath:
            WishlistPic,
    },
    {
        label: 'Obrázek 5',
        imgPath:
            StorePic,
    },
    {
        label: 'Obrázek 6',
        imgPath:
            PartnerPic,
    },
    // Přidejte další obrázky podle potřeby
];

function MainScene() {
    const { t } = useTranslation();
    const theme = useTheme();
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const navigate = useNavigate();
    const [facebookLoginOpen, setFacebookLoginOpen] = useState(false);
    const facebookResponse = () => {
        //@ts-ignore
        ReactNativeWebView.postMessage("tenhle");
    }
    const goToSignUp = () => {
        navigate('signup');
    }

    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = tutorialSteps.length;

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveStep((prevActiveStep) => prevActiveStep + 1 === maxSteps ? 0 : prevActiveStep + 1);
        }, 3500); // Obrázky se budou měnit každé 3 sekundy

        return () => {
            clearInterval(interval);
        };
    }, []);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div className="App" onScroll={(e) => { console.log(e) }}>

            <header className="App-header">
                <Navbar />


                {biggerThan700 &&
                    //@ts-ignore
                    <img src={layoutPic} fetchPriority='high' style={{ width: '100%', marginTop: '60px', height: 'auto' }} />

                }
                {!biggerThan700 &&
                    //@ts-ignore
                    <img src={layoutPicMobile} fetchPriority='high' style={{ height: '100%', marginTop: '60px' }} />
                }

                {biggerThan700 &&

                    <div>
                        <div>
                            {/* <div style={{ marginTop: '-280px', position: 'relative', left: '76%', right: '24%'}}>
                            <iframe width="336" height="190" style={{border: '2px solid #F34848', borderRadius:'16px'}} src="https://www.youtube.com/embed/TE2NAs_ErP0?si=1ATCOAIQqIqlq6Ar&autoplay=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                        </div> */}
                            <motion.div initial={{ opacity: 0, scale: 0.5 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 1 }} className='cardTransparent' style={{ marginTop: '-440px' }} >
                                <Typography variant='h1' fontWeight='normal' style={{ color: '#F34848' }}>{t('GentlemanAssistent')}</Typography>
                                <Typography variant='h3' fontWeight='normal' style={{ color: 'white' }}>{t('DontForgetYourLovedOnes')}</Typography>
                                <Typography variant='body1' sx={{ marginTop: '20px', fontSize: '24px', maxWidth: '72%' }}>{t('OurAppGivesYouPersonPlan')} <b style={{ color: '#F34848' }}>{t('HowToAchieveLove')}</b>{t('YouEverWanted')}</Typography>
                                <Button variant='contained' onClick={() => { navigate('/signuploi') }}
                                    sx={{ minWidth: biggerThan700 ? '240px' : '150px', minHeight: biggerThan700 ? '60px' : '40px', fontSize: '20px', position: 'relative', left: '70%', right: '30%' }} >{t('Register')}</Button>
                                <Button variant='outlined' onClick={() => navigate('/ebook')} sx={{ marginLeft: '-240px', marginTop: '10px' }}>Ebook - Jak posílit vztah</Button>

                            </motion.div>
                        </div>
                        {/* <div style={{position: 'relative', display: 'flex', right: '25%', left: '75%'}}>
                            <img src={mobilscreen} style={{ maxWidth: biggerThan700 ? '312px' : '340px', marginRight: biggerThan700 ? '-80px' : '0px', marginTop: biggerThan700 ? '-600px' : '60px' }}></img>
                        </div> */}
                        <div style={{ maxWidth: '268px', position: 'relative', marginTop: '-584px', right: '25%', left: '75%' }}>
                            <img
                                src={tutorialSteps[activeStep].imgPath}
                                alt={tutorialSteps[activeStep].label}
                                style={{ width: '100%', height: 'auto', borderRadius: '20px', border: '4px solid #F34848', padding: '0px', boxShadow: '1px 2px 9px #F34848' }}
                            />
                            <MobileStepper sx={{ background: 'transparent' }}
                                steps={maxSteps}
                                position="static"
                                variant="dots"
                                activeStep={activeStep}

                                nextButton={
                                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>

                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>

                                    </Button>
                                }
                            />

                        </div>

                    </div>

                }



                {!biggerThan700 &&
                    <div>
                        <div className='cardTransparentMobile'>

                            <Typography variant='h3' fontWeight='normal' style={{ color: '#F34848' }}>{t('GentlemanAssistent')}</Typography>
                            <Typography variant='h5' fontWeight='normal' style={{ color: 'white' }}>{t('DontForgetYourLovedOnes')}</Typography>
                            <Typography variant='body1' sx={{ marginTop: '20px', fontSize: '20px' }}>{t('OurAppGivesYouPersonPlan')} <b style={{ color: '#F34848' }}>{t('HowToAchieveLove')}</b>{t('YouEverWanted')}</Typography>
                            <Button variant='contained' onClick={() => { navigate('/signuploi') }}
                                sx={{ minWidth: '292px', minHeight: biggerThan700 ? '60px' : '40px', fontSize: '20px', marginTop: '20px' }} >{t('Register')}</Button>
                            <Button variant='outlined' onClick={() => navigate('/ebook')} sx={{ padding: '10px', marginTop: '20px', minWidth: '292px' }}>Ebook - Jak posílit vztah</Button>
                        </div>
                        {/* <div className='flexRoW' style={{ position: 'relative', display: 'flex', justifyContent: 'center', backgroundColor: '#F5F5F5' }}>
                            <img src={mobilscreen} style={{ maxWidth: biggerThan700 ? '320px' : '340px', marginRight: biggerThan700 ? '-80px' : '0px', marginTop: biggerThan700 ? '0px' : '40px' }}></img>
                        </div> */}
                        <div style={{ maxWidth: '352px', position: 'relative', paddingLeft: '32px', paddingTop: '80px' }}>
                            <img
                                src={tutorialSteps[activeStep].imgPath}
                                alt={tutorialSteps[activeStep].label}
                                style={{ width: '100%', height: 'auto', borderRadius: '20px', border: '4px solid #F34848', padding: '0px', boxShadow: '1px 2px 9px #F34848' }}
                            />
                            <MobileStepper sx={{ background: 'transparent' }}
                                steps={maxSteps}
                                position="static"
                                variant="dots"
                                activeStep={activeStep}

                                nextButton={
                                    <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>

                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>

                                    </Button>
                                }
                            />

                        </div>
                    </div>

                }

                <div id="app" className='flexRow' style={{ justifyContent: biggerThan700 ? 'space-evenly' : 'space-evenly', flexWrap: biggerThan700 ? 'nowrap' : 'wrap' }}>
                    {/* <div style={{ marginTop: '12px', display: biggerThan700 ? 'none' : 'block' }}>
                        <iframe width="336" height="189" style={{border: '2px solid white', borderRadius:'16px'}} src="https://www.youtube.com/embed/TE2NAs_ErP0?si=1ATCOAIQqIqlq6Ar&autoplay=1&mute=1" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div> */}
                    <div className={biggerThan700 ? 'cardPicture' : 'cardPictureMobile'}>
                        <Typography variant='h4' fontWeight='normal' color='#F34848'><SquareFootOutlined fontSize='large' sx={{ marginRight: '8px', mb: '-6px' }}></SquareFootOutlined>{t('Reminds')}</Typography>
                        <div className='cardPic'>
                            <img src={personalised} width='280px' style={{ borderRadius: '16px 16px 0px 0px' }}></img>
                            <Typography variant='body1' sx={{ padding: '10px 20px 10px 30px', height: '220px' }}>{t('RemindsText1')} <b style={{ color: '#F34848' }}>{t('RemindsText2')} </b> {t('RemindsText3')}</Typography>
                        </div>
                    </div>
                    <div className={biggerThan700 ? 'cardPicture' : 'cardPictureMobile'}>
                        <div style={{ textAlign: 'left' }}>
                            <Typography variant='h4' fontWeight='normal' color='#F34848'><StackedBarChartOutlined fontSize='large' sx={{ marginRight: '8px', mb: '-6px' }}></StackedBarChartOutlined>{t('Advices')}</Typography>
                        </div>
                        <div className='cardPic'>
                            <img src={clear} width='280px' style={{ borderRadius: '16px 16px 0px 0px' }}></img>
                            <Typography variant='body1' sx={{ padding: '10px 20px 10px 30px', height: '220px' }}>{t('AdvicesText1')} <b style={{ color: '#F34848' }}>{t('AdvicesText2')}</b> {t('AdvicesText3')}</Typography>
                        </div>
                    </div>
                    <div className={biggerThan700 ? 'cardPicture' : 'cardPictureMobile'}>
                        <div style={{ textAlign: 'left' }}>
                            <Typography variant='h4' fontWeight='normal' color='#F34848'><HandymanOutlined fontSize='large' sx={{ marginRight: '8px', mb: '-4px' }}></HandymanOutlined>{t('Plans')}</Typography>
                        </div>
                        <div className='cardPic'>
                            <img src={practical} width='280px' style={{ borderRadius: '16px 16px 0px 0px' }}></img>
                            <Typography variant='body1' sx={{ padding: '10px 20px 10px 30px', height: '220px' }}>{t('PlansText1')}<b style={{ color: '#F34848' }}> {t('PlansText2')}</b> {t('PlansText3')}</Typography>
                        </div>
                    </div>
                    {/* <div className={biggerThan700 ? 'cardPicture' : 'cardPictureMobile'}>
                        <div style={{ textAlign: 'left' }}>
                            <Typography variant='h3' fontWeight='normal' color='#272727'><DoneAllOutlined fontSize='large' sx={{ marginRight: '8px' }}></DoneAllOutlined>{t('Prepares')}</Typography>
                        </div>
                        <div className='cardPic'>
                            <img src={pripravi} width='280px' style={{ borderRadius: '16px 16px 0px 0px' }}></img>
                            <Typography variant='body1' sx={{ padding: '10px 20px 10px 30px', height: '220px' }}>{t('PreparesText1')} <b style={{ color: '#F34848' }}>{t('PreparesText2')}</b> {t('PreparesText3')}</Typography>
                        </div>
                    </div> */}

                </div>
                <div className='flexRow4'>
                    <Typography variant='h3' sx={{ color: '#272727', marginBottom: '-20px', zIndex: '20', position: 'relative', display: 'flex', textAlign: 'left', justifyContent: 'space-between' }}>{t('Challenges')}</Typography>
                </div>
                <div id="vyzvy">
                    <div className='flexRow' style={{ justifyContent: biggerThan700 ? 'space-between' : 'space-evenly', flexWrap: biggerThan700 ? 'nowrap' : 'wrap', padding: biggerThan700 ? '' : '2em', }}>

                        <div className='cardPic3' style={{ maxWidth: '320px' }}>
                            <Typography variant='h4' sx={{ color: '#F34848', marginBottom: '12px' }}>{t('LoveControl')}</Typography>
                            <Typography variant='body1'>{t('LoveControlParagraph')}</Typography>
                            <img src={stavLasky} style={{ marginTop: '1.5em', width: '100%', borderRadius: '16px' }}></img>
                        </div>
                        <div className='cardPic3' style={{ maxWidth: '320px' }}>
                            <Typography variant='h4' sx={{ color: '#F34848', marginBottom: '12px' }}>{t('NewChallenge')}</Typography>
                            <Typography variant='body1'>{t('NewChallengeParagraph')}</Typography>
                            <img src={novaVyzva} style={{ marginTop: '1.5em', width: '100%', borderRadius: '16px' }}></img>
                        </div>
                        <div className='cardPic3' style={{ maxWidth: '320px' }}>
                            <Typography variant='h4' sx={{ color: '#F34848', marginBottom: '12px' }}>{t('ChallengeAccepted')}</Typography>
                            <Typography variant='body1'>{t('ChallengeAcceptedParagraph')}</Typography>
                            <img src={prijetiVyzvy} style={{ marginTop: '1.5em', width: '100%', borderRadius: '16px' }}></img>
                        </div>
                        <div className='cardPic3' style={{ maxWidth: '320px' }}>
                            <Typography variant='h4' sx={{ color: '#F34848', marginBottom: '12px' }}>{t('ChallengeFinished')}</Typography>
                            <Typography variant='body1'>{t('ChallengeFinishedParagraph')} </Typography>
                            <img src={splneniVyzvy} style={{ marginTop: '1.5em', width: '100%', borderRadius: '16px' }}></img>
                        </div>
                    </div>
                </div>

                <div>

                    <div id="kviz" className='flexRowClose'>
                        <div className='cardPicQuizDesk' style={{ borderRadius: biggerThan700 ? '16px 0px 0px 16px' : '16px 16px 16px 16px' }}>
                            <div>
                                <Typography variant='h4' sx={{ padding: '20px 32px 10px 32px', color: '#f34848' }}><QuizOutlined fontSize='medium' sx={{ marginRight: '8px' }}></QuizOutlined>{t('LoveQuiz')}</Typography>
                                <div style={{ textAlign: 'center', marginBottom: '8px' }}>
                                    <Button variant='contained' onClick={() => { navigate('quiz') }} startIcon={<PlayCircleOutline sx={{ minWidth: '32px', minHeight: '32px' }} />}
                                        sx={{ display: biggerThan700 ? 'none' : 'visible', minWidth: biggerThan700 ? '140px' : '150px', minHeight: biggerThan700 ? '40px' : '40px', fontSize: '20px' }} >{t('Quiz')}</Button>
                                </div>
                                <Typography variant='body1' sx={{ padding: '4px 32px 32px 32px' }}>{t('LoveQuizWhatYouDoWrong')}</Typography>
                                <Typography variant='body1' sx={{ padding: '4px 32px 32px 32px' }}>{t('LoveQuizUnderstandConcept')}</Typography>

                                <div style={{ color: '#f34848', paddingLeft: '20px', paddingBottom: '10px' }}>
                                    <div style={{ paddingRight: '1em', paddingLeft: '1em', marginLeft: biggerThan700 ? '-20px' : '0px', marginRight: biggerThan700 ? '0px' : '20px', display: biggerThan700 ? 'flex' : 'block', justifyContent: 'space-around', flexWrap: 'wrap', marginTop: '-12px' }}>

                                        <div className={biggerThan700 ? 'jazykBG' : 'jazykBG-mobile'} style={{ backgroundImage: `url(${dotek})`, border: '1px solid #F3484880' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: biggerThan700 ? '14px' : '16px', backgroundColor: '#272727CC', borderRadius: '8px', margin: biggerThan700 ? '16px 10px 10px 10px' : '0px 40px 0px 40px', padding: '16px 8px 8px 8px' }}><TouchAppOutlined sx={{ marginRight: '8px', marginBottom: '-4px' }}></TouchAppOutlined>{t('Touch')}</Typography>
                                        </div>
                                        <div className={biggerThan700 ? 'jazykBG' : 'jazykBG-mobile'} style={{ backgroundImage: `url(${sluzba})`, border: '1px solid #F3484880' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: biggerThan700 ? '14px' : '16px', backgroundColor: '#272727CC', borderRadius: '8px', margin: biggerThan700 ? '16px 4px 10px 4px' : '0px 40px 0px 40px', padding: '16px 8px 8px 8px' }}><CleaningServicesOutlined sx={{ marginRight: '8px', marginBottom: '-4px' }}></CleaningServicesOutlined>{t('Service')}</Typography>
                                        </div>
                                        <div className={biggerThan700 ? 'jazykBG' : 'jazykBG-mobile'} style={{ backgroundImage: `url(${cas})`, border: '1px solid #F3484880' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: biggerThan700 ? '14px' : '16px', backgroundColor: '#272727CC', borderRadius: '8px', margin: biggerThan700 ? '16px 10px 10px 10px' : '0px 40px 0px 40px', padding: '16px 16px 8px 12px' }}><AccessTimeOutlined sx={{ marginRight: '8px', marginBottom: '-4px' }}></AccessTimeOutlined>{t('Time')}</Typography>
                                        </div>
                                        <div className={biggerThan700 ? 'jazykBG' : 'jazykBG-mobile'} style={{ backgroundImage: `url(${darek})`, border: '1px solid #F3484880' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: biggerThan700 ? '14px' : '16px', backgroundColor: '#272727CC', borderRadius: '8px', margin: biggerThan700 ? '16px 10px 10px 10px' : '0px 40px 0px 40px', padding: '16px 8px 8px 8px' }}><CardGiftcardOutlined sx={{ marginRight: '8px', marginBottom: '-4px' }}></CardGiftcardOutlined>{t('Gift')}</Typography>
                                        </div>
                                        <div className={biggerThan700 ? 'jazykBG' : 'jazykBG-mobile'} style={{ backgroundImage: `url(${kompliment})`, border: '1px solid #F3484880' }}>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: biggerThan700 ? '14px' : '16px', backgroundColor: '#272727CC', borderRadius: '8px', margin: biggerThan700 ? '16px 4px 10px 4px' : '0px 40px 0px 40px', padding: '16px 8px 8px 8px' }}><KeyboardVoiceOutlined sx={{ marginRight: '8px', marginBottom: '-4px' }}></KeyboardVoiceOutlined>{t('Praise')}</Typography>
                                        </div>
                                    </div>
                                </div>
                                <Typography variant='body1' sx={{ padding: '4px 32px 32px 32px' }}>{t('LoveQuizMixText1')} <b style={{ color: '#F34848' }}>{t('LoveQuizMixText2')}</b> {t('LoveQuizMixText3')}</Typography>

                                <Typography variant='body1' sx={{ padding: '4px 32px 32px 32px' }}>{t('LoveQuizKeyToSuccessText1')} <b style={{ color: '#F34848' }}>{t('LoveQuizKeyToSuccessText2')}</b> {t('LoveQuizKeyToSuccessText3')}</Typography>
                            </div>
                        </div>

                        <div className='cardPicQuiz' style={{ borderRadius: biggerThan700 ? '0px 16px 16px 0px' : '16px 16px 16px 16px' }}>
                            <div className='loveguardBackground' style={{ borderRadius: biggerThan700 ? '0px 16px 16px 0px' : '16px 16px 16px 16px' }}>
                                <div className='cardTransparentInner' style={{ position: 'relative', top: biggerThan700 ? '25%' : '7%', left: '10%', marginRight: '20%' }}>
                                    {/* <div>
                                        <img src= {LGcage} width='100%' style={{borderRadius: '0px 16px 16px 0px'}}></img>
                                    </div> */}
                                    <Typography variant='body1' sx={{ marginTop: biggerThan700 ? '20px' : '0px', fontSize: '16px', maxWidth: '100%' }}><b style={{ color: '#F34848' }}>{t('LoveQuizKnowYourPrimaryTypeText1')}</b> {t('LoveQuizKnowYourPrimaryTypeText2')}</Typography>
                                    <div style={{ position: 'relative', textAlign: 'center', marginTop: '20px', marginBottom: '-20px' }}>
                                        <Button variant='contained' onClick={() => { navigate('quiz') }} startIcon={<PlayCircleOutline sx={{ minWidth: '32px', minHeight: '32px' }} />}
                                            sx={{ minWidth: biggerThan700 ? '240px' : '150px', minHeight: biggerThan700 ? '60px' : '40px', fontSize: '20px' }} >{t('Quiz')}</Button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>



                </div>

                <div className='flexRow' style={{ paddingTop: '80px', justifyContent: biggerThan700 ? 'space-between' : 'space-evenly', flexWrap: biggerThan700 ? 'nowrap' : 'wrap' }}>

                    <div>
                        <div className='cardReview' style={{ padding: '20px' }}>
                            <div style={{ textAlign: 'center' }}><img src={Review1} style={{ width: '100px', borderColor: '#f34848', borderStyle: 'solid', borderRadius: '100px', borderWidth: '1px', marginTop: '-72px' }} /></div>
                            <Typography variant='h4' sx={{ textAlign: 'center', paddingBottom: '8px' }}>{t('ReviewTitle1')}</Typography>
                            <div style={{ color: '#f34848', textAlign: 'center', paddingBottom: '8px' }}>

                                <StarRateSharp></StarRateSharp>

                                <StarRateSharp></StarRateSharp>

                                <StarRateSharp></StarRateSharp>

                                <StarRateSharp></StarRateSharp>

                                <StarRateSharp></StarRateSharp>

                            </div>
                            <Typography>{t('ReviewText1')}</Typography>
                        </div>
                    </div>

                    <div>
                        <div className='cardReview' style={{ padding: '20px' }}>
                            <div style={{ textAlign: 'center' }}><img src={Review2} style={{ width: '100px', borderColor: '#f34848', borderStyle: 'solid', borderRadius: '100px', borderWidth: '1px', marginTop: '-72px' }} /></div>
                            <Typography variant='h4' sx={{ textAlign: 'center', paddingBottom: '8px' }}>{t('ReviewTitle2')}</Typography>
                            <div style={{ color: '#f34848', textAlign: 'center', paddingBottom: '8px' }}>

                                <StarRateSharp></StarRateSharp>

                                <StarRateSharp></StarRateSharp>

                                <StarRateSharp></StarRateSharp>

                                <StarRateSharp></StarRateSharp>

                                <StarRateOutlined></StarRateOutlined>
                            </div>
                            <Typography>{t('ReviewText2')}</Typography>
                        </div>
                    </div>
                    <div>
                        <div className='cardReview' style={{ padding: '20px' }}>
                            <div style={{ textAlign: 'center' }}><img src={Review3} style={{ width: '100px', borderColor: '#f34848', borderStyle: 'solid', borderRadius: '100px', borderWidth: '1px', marginTop: '-72px' }} /></div>
                            <Typography variant='h4' sx={{ textAlign: 'center', paddingBottom: '8px' }}>{t('ReviewTitle3')}</Typography>
                            <div style={{ color: '#f34848', textAlign: 'center', paddingBottom: '8px' }}>

                                <StarRateSharp></StarRateSharp>

                                <StarRateSharp></StarRateSharp>

                                <StarRateSharp></StarRateSharp>

                                <StarRateSharp></StarRateSharp>

                                <StarRateSharp></StarRateSharp>

                            </div>
                            <Typography>{t('ReviewText3')}</Typography>
                        </div>
                    </div>




                </div>
                <div className='flexRow' style={{ flexDirection: biggerThan700 ? 'initial' : 'column-reverse', flexWrap: 'wrap', padding: '52px' }}>
                    <div className='cardPic3' style={{ width: '280px', paddingBottom: '0px' }}>
                        <div style={{ position: 'relative', textAlign: 'center' }}>
                            <Typography variant='h4'>
                                {t('FreeSubType')}
                            </Typography>
                            <Typography p='12px' fontWeight='bold'>
                                {t('PriceFree')}
                            </Typography>
                            <Button variant='contained' sx={{ marginBottom: '20px', minWidth: '180px' }} onClick={() => { navigate('/signuploi') }}>{t('Download')}</Button>
                        </div>
                        <div style={{ backgroundColor: '#2f2f2f', marginLeft: '-1.5em', padding: '20px', marginRight: '-1.5em' }}>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubChallenges')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubStore')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubDashboard')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubNotifications')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubProfiles')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubAIMentor')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography >{t('SubPlanner')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <CancelOutlined sx={{ width: '20px', color: 'grey', marginRight: '12px' }} />
                                <Typography sx={{ color: 'grey' }}>{t('SubWishlist')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <CancelOutlined sx={{ width: '20px', color: 'grey', marginRight: '12px' }} />
                                <Typography sx={{ color: 'grey' }}>{t('SubPartnersInterface')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <CancelOutlined sx={{ width: '20px', color: 'grey', marginRight: '12px' }} />
                                <Typography sx={{ color: 'grey' }}>{t('SubReports')}</Typography>
                            </div>
                        </div>
                        <div>
                            <Typography variant='body2' sx={{ fontSize: '10px', marginTop: '4px', color: '#FFFFFF80' }}>
                                {t('SubFreeRestrictions')}
                            </Typography>
                        </div>
                    </div>
                    <div className='cardPic3' style={{ width: '280px' }}>
                        <div style={{ position: 'relative', textAlign: 'center' }}>
                            <Typography variant='h4'>
                                {t('PremiumSubType')}
                            </Typography>
                            <Typography p='12px' fontWeight='bold'>
                                {t('PricePremium')}
                            </Typography>
                            <Button variant='contained' sx={{ marginBottom: '20px', minWidth: '180px' }} onClick={() => { navigate('/signuploi') }}>{t('TryForFree')}</Button>
                        </div>
                        <div style={{ backgroundColor: '#2f2f2f', marginLeft: '-1.5em', padding: '20px', marginRight: '-1.5em' }}>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubChallengesPremium')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubStorePremium')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubDashboard')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubNotificationsOptional')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubProfiles')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubAIMentor')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubPlanner')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubWishlist')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <CancelOutlined sx={{ width: '20px', color: 'grey', marginRight: '12px' }} />
                                <Typography sx={{ color: 'grey' }}>{t('SubPartnersInterface')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <CancelOutlined sx={{ width: '20px', color: 'grey', marginRight: '12px' }} />
                                <Typography sx={{ color: 'grey' }}>{t('SubReports')}</Typography>
                            </div>
                        </div>

                    </div>
                    <div className='cardPic3' style={{ width: '280px', border: '4px solid #F34848' }}>
                        <div style={{ position: 'relative', textAlign: 'center' }}>
                            <Typography sx={{ marginTop: '-70px', paddingBottom: '40px', color: '#F34848', fontWeight: 'bold' }}>
                                {t('Popular')}
                            </Typography>
                            <Typography variant='h4'>
                                {t('Premium+SubType')}
                            </Typography>
                            <Typography p='12px' fontWeight='bold'>
                                {t('PricePremium+')}
                            </Typography>
                            <Button variant='contained' sx={{ marginBottom: '20px', minWidth: '180px' }} onClick={() => { navigate('/signuploi') }}>{t('TryForFree')}</Button>
                        </div>
                        <div style={{ backgroundColor: '#2f2f2f', marginLeft: '-1.5em', padding: '20px', marginRight: '-1.5em' }}>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubChallengesPremium+')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubStorePremium+')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubDashboard')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubNotificationsOptional')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubProfiles')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubAIMentor')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubPlanner')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubWishlist')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubPartnersInterface')}</Typography>
                            </div>
                            <div style={{ position: 'relative', display: 'flex' }}>
                                <Check sx={{ width: '20px', color: '#F34848', marginRight: '12px' }} />
                                <Typography>{t('SubReports')}</Typography>
                            </div>
                        </div>

                    </div>

                </div>

                {/* <div id="app" style={{width: '100%', background: '#F5F5F5', zIndex: 1000, fontFamily: 'Cinzel Decorative'}}>
                    <Typography className='section' variant='h4' marginLeft='10%' marginBottom='16px' marginTop='80px'>{t('Product')}</Typography>
                    <div style={{width:'50%', marginLeft:'25%'}}>
                    <Carousel/>
                    </div>
                </div> */}

                {/* <div id="about" style={{width: '100%', background: '#F5F5F5', zIndex: 1000, fontFamily: 'Cinzel Decorative'}}>
                    <Typography className='section' variant='h4' marginLeft='10%' marginBottom='16px' marginTop='80px'>{t('AboutUs')}</Typography>
                    <div className='card'>
                        <Grid container>
                            <Grid item lg={4} md={6} sm={12}>
                                <Typography variant='body1' align='left' >{t('AboutUsText')}</Typography>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} padding='20px'>
                                <img src={Jakub} style={{ width: '30%' }} />
                                <Typography variant='subtitle1' color='#F34848' fontWeight='bold'>Jakub Forman</Typography>
                                <Typography variant='subtitle1' fontWeight='bold'>Founder & CEO</Typography>
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} padding='20px'>
                                <img src={Lukas} style={{ width: '30%' }} />
                                <Typography variant='subtitle1' color='#F34848' fontWeight='bold'>Lukáš Kluch</Typography>
                                <Typography variant='subtitle1' fontWeight='bold'>Co-founder & CTO</Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div> */}
                <div id="contact" style={{ height: 'auto', width: '100%', background: '#272727', zIndex: 1000 }}>

                    <div style={{ display: 'flex', justifyContent: 'space-evenly', margin: '2em' }}>
                        <Button target="_blank" href='https://www.facebook.com/profile.php?id=100089619233058'><FacebookOutlined></FacebookOutlined></Button>
                        <Button target="_blank" href='https://www.instagram.com/loveguard_official/'><Instagram></Instagram></Button>
                        <Button target="_blank" href='https://twitter.com/Loveguard_tweet'><Twitter></Twitter></Button>
                        <Button target="_blank" href='https://www.youtube.com/@loveguard6430'><YouTube></YouTube></Button>
                        <Button target="_blank" href='https://www.linkedin.com/company/loveguard/'><LinkedIn></LinkedIn></Button>
                    </div>
                    <Typography className='section' variant='h4' marginLeft='6%' marginBottom='16px' color='white' marginTop='40px'>{t('Contact')}</Typography>
                    <div>
                        <Typography variant='body1' marginLeft='6%'>Jakub Forman</Typography>
                    </div>
                    <div>
                        <Typography variant='body1' marginLeft='6%'>forman@loveguard.cloud</Typography>
                    </div>
                    <div style={{ marginTop: '40px', marginLeft: '6%' }}>
                        <Button variant='text' size='small' onClick={() => { window.open('/TermsAndConditionsApp', '_blank') }}>Smluvní podmínky</Button>
                    </div>
                    <div style={{ marginBottom: '40px', marginLeft: '6%' }}>
                        <Button variant='text' size='small' onClick={() => { window.open('/PrivacyPolicyApp', '_blank') }}>Zásady ochrany osobních údajů</Button>
                    </div>
                </div>
            </header >
        </div >);
}
export default MainScene;