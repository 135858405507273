import React from 'react'
import ReactMarkdown from 'react-markdown';

export default function TermsAndConditionsApp() {
    return (
        <div style={{ padding: '40px' }}>
            <ReactMarkdown>
                {`
**Smluvní podmínky** 

  

Tyto obchodní podmínky se vztahují na aplikaci LOVEGUARD (dále jen „Aplikace“) pro mobilní zařízení, kterou vytvořila společnost LOVEGUARD s.r.o. (dále jen „poskytovatel služeb“) jako služba Freemium. 

 

Stažením nebo používáním aplikace automaticky souhlasíte s následujícími podmínkami. Důrazně doporučujeme, abyste si před použitím aplikace důkladně přečetli a porozuměli těmto podmínkám. Neoprávněné kopírování, úprava aplikace, jakékoli části aplikace nebo našich ochranných známek je přísně zakázáno. Jakékoli pokusy o extrahování zdrojového kódu Aplikace, překlad Aplikace do jiných jazyků nebo vytvoření odvozených verzí nejsou povoleny. Veškeré ochranné známky, autorská práva, databázová práva a další práva duševního vlastnictví související s Aplikací zůstávají vlastnictvím Poskytovatele služeb. 

 

Poskytovatel služeb se snaží zajistit, aby aplikace byla co nejvýhodnější a nejefektivnější. Jako takové si vyhrazují právo kdykoli a z jakéhokoli důvodu upravit aplikaci nebo účtovat poplatky za své služby. Poskytovatel služeb vás ujišťuje, že veškeré poplatky za aplikaci nebo její služby vám budou jasně sděleny. 

 

Aplikace uchovává a zpracovává osobní údaje, které jste poskytli Poskytovateli služby za účelem poskytování Služby. Je vaší odpovědností udržovat zabezpečení vašeho telefonu a přístupu k Aplikaci. Poskytovatel služeb důrazně nedoporučuje útěk z vězení nebo rootování vašeho telefonu, což zahrnuje odstranění softwarových omezení a omezení uložených oficiálním operačním systémem vašeho zařízení. Takové akce mohou vystavit váš telefon malwaru, virům, škodlivým programům, ohrozit bezpečnostní funkce vašeho telefonu a mohou způsobit, že Aplikace nebude fungovat správně nebo vůbec. 

 

Vezměte prosím na vědomí, že Aplikace využívá služby třetích stran, které mají své vlastní Podmínky. Níže jsou uvedeny odkazy na smluvní podmínky poskytovatelů služeb třetích stran používaných aplikací: 

* [Služby Google Play](https://policies.google.com/terms) 

* [AdMob](https://developers.google.com/admob/terms) 

* [Google Analytics pro Firebase](https://www.google.com/analytics/terms/) 

* [Facebook](https://www.facebook.com/legal/terms/plain_text_terms) 

* [Mixpanel](https://mixpanel.com/legal/terms-of-use/) 

Vezměte prosím na vědomí, že poskytovatel služeb nepřebírá odpovědnost za určité aspekty. Některé funkce Aplikace vyžadují aktivní internetové připojení, kterým může být Wi-Fi nebo poskytované vaším poskytovatelem mobilní sítě. Poskytovatel služby nemůže nést odpovědnost, pokud Aplikace nefunguje na plnou kapacitu z důvodu nedostatku přístupu k Wi-Fi nebo pokud jste vyčerpali svůj datový limit. 

 

Pokud aplikaci používáte mimo oblast Wi-Fi, mějte prosím na paměti, že stále platí smluvní podmínky vašeho poskytovatele mobilní sítě. V důsledku toho vám může váš mobilní operátor účtovat poplatky za využití dat během připojení k aplikaci nebo jiné poplatky třetích stran. Používáním aplikace přijímáte odpovědnost za veškeré takové poplatky, včetně poplatků za roamingová data, pokud aplikaci používáte mimo své domovské území (tj. region nebo zemi) bez deaktivace datového roamingu. Pokud nejste plátcem účtu za zařízení, na kterém aplikaci používáte, předpokládá se, že jste získali povolení od plátce účtu. 

  

Stejně tak poskytovatel služeb nemůže vždy převzít odpovědnost za vaše používání aplikace. Je například vaší odpovědností zajistit, aby vaše zařízení zůstalo nabité. Pokud se vašemu zařízení vybije baterie a vy nemůžete získat přístup ke Službě, poskytovatel služby nenese odpovědnost. 

  

Pokud jde o odpovědnost poskytovatele služeb za vaše používání aplikace, je důležité si uvědomit, že i když se snaží zajistit, aby byla vždy aktuální a přesná, spoléhají na třetí strany, že jim poskytnou informace, aby vám byly k dispozici. Poskytovatel služeb nenese žádnou odpovědnost za jakoukoli ztrátu, přímou nebo nepřímou, ke které dojde v důsledku toho, že se budete plně spoléhat na tuto funkci aplikace. 

 

Poskytovatel služeb může chtít aplikaci v určitém okamžiku aktualizovat. Aplikace je aktuálně dostupná podle požadavků na operační systém (a pro všechny další systémy, na které se rozhodnou rozšířit dostupnost aplikace), se mohou změnit a pokud budete chtít aplikaci nadále používat, budete si muset stáhnout aktualizace. Poskytovatel služeb nezaručuje, že vždy aktualizuje aplikaci tak, aby byla pro vás relevantní a/nebo kompatibilní s konkrétní verzí operačního systému nainstalovaného ve vašem zařízení. Souhlasíte však s tím, že vždy přijmete aktualizace aplikace, když vám budou nabídnuty. Poskytovatel služeb si také může přát ukončit poskytování aplikace a může její používání kdykoli ukončit, aniž by vám zaslal oznámení o ukončení. Pokud vás neinformují jinak, při jakémkoli ukončení (a) skončí práva a licence udělené vám v těchto podmínkách; (b) musíte přestat používat aplikaci a (v případě potřeby) ji odstranit ze svého zařízení. 

 

**Změny těchto smluvních podmínek** 

  

Poskytovatel služeb může pravidelně aktualizovat své Podmínky. Proto vám doporučujeme, abyste na této stránce pravidelně kontrolovali případné změny. Poskytovatel služeb vás bude informovat o jakýchkoli změnách zveřejněním nových podmínek na této stránce. 

  

Tyto obchodní podmínky jsou účinné od 28.08.2024 

  

**Kontaktujte nás** 

  

Máte-li jakékoli dotazy nebo návrhy týkající se Smluvních podmínek, neváhejte kontaktovat poskytovatele služeb na adrese forman@loveguard.cloud. 

  

* * * 
                `}
            </ReactMarkdown>
        </div>
    )
}
