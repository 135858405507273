import React, { useState } from 'react'
import ebookbait from '../../Assets/EbookBait.png';
import { Button, TextField, Typography } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment'
import MailOutline from '@mui/icons-material/MailOutline';
import { useMediaPredicate } from 'react-media-hook';
import { LoveguardApiClient } from '../../Api/ApiBaseClient';
import { EbookRequest } from '../../Api/ApiServer';



export default function Ebook() {
    const [mail, setMail] = useState<string>('');
    const [textOdeslano, setTestOdeslano] = useState<string>('Získat Ebook');
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    const delayInMilliseconds = 1000;


    return (
        <div>
            <img src={ebookbait} style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', width: '100%' }}></img>

            <div style={{
                borderRadius: '16px', boxShadow: '0px -2px 16px #F34848',
                textAlign: 'center', display: 'block', position: 'fixed', top: biggerThan700 ? '38%' : '12%', left: biggerThan700 ? '32%' : '10%', maxWidth: '80%', height: 'auto', backgroundColor: '#F5F5F5', padding: '20px'

            }}>
                <div>
                    <Typography variant='h4' color='#404040'>Pošleme vám odkaz na Ebook emailem.</Typography>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <TextField placeholder='Zde zadejte email'
                        value={mail}
                        onChange={(e) => { setMail(e.currentTarget.value) }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MailOutline />
                                </InputAdornment>
                            ),
                        }}
                        required id="outlined-basic" label="Emailová Adresa" variant="outlined"></TextField>
                </div>
                <div>
                    <Button disabled={mail === ''} onClick={() => { new LoveguardApiClient().GetClientToAuth().sendEbookMail(new EbookRequest({ email: mail })).then(() => {setTestOdeslano('Úspěšně odeslán'); }) }} placeholder="Zadejte váš email" size='large' variant='contained' style={{ marginTop: '20px' }} >{textOdeslano}</Button>
                </div>

            </div>
        </div>
    )
}
