import React from 'react'
import { useMediaPredicate } from 'react-media-hook'
import LoI from './LoI';
import LoIBig from './LoIBig';

export default function LoIMain() {
    const biggerThan700 = useMediaPredicate("(min-width: 700px)");
    if(!biggerThan700)
    return (<LoI/>)
    else return (<LoIBig/>)
}
